import { DOCUMENT, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { Store } from '@ngrx/store';
import { KdsSettings, User } from 'src/app/models/user';
import { State } from 'src/app/reducers';
import {
  cutPaper,
  disconnectPrinter,
  feedPaper,
  findPrinter,
} from 'src/app/reducers/printer/printer.actions';
import { setSectionsDisplayed } from 'src/app/reducers/sections/sections.actions';
import {
  clearSectionTickets,
  fetchAllSectionTickets,
} from 'src/app/reducers/tickets/tickets.actions';
import {
  changeKdsSettings,
  saveSectionsSelection,
  setKdsSettings,
} from 'src/app/reducers/user/user.actions';

import { connectPrinter } from './../../reducers/printer/printer.actions';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'chef-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatSlideToggleModule,
    NgClass,
    TranslocoPipe,
  ],
})
export class SettingsComponent implements OnChanges, OnInit {
  @Input() kdsSettings!: KdsSettings | null;
  @Input() printerConnected!: boolean | null;
  @Input() printerName!: string | null;
  @Input() printerPaired = false;
  @Input() sections!: string[] | null;
  @Input() sectionsDisplayed!: string[] | null;
  @Input() user!: User | null;
  @Output() toggle = new EventEmitter<void>();

  textSizes = [100, 125, 150];
  pageSizes = [5, 6, 10, 20, 50];
  form = this.formBuilder.group({
    section: new FormArray<FormControl<boolean>>([]),
    auto_print: false,
    show_consumer_name: false,
    show_consumer_room: false,
    show_consumer_diets: false,
    show_consumer_type: false,
    show_consumer_profile: false,
    show_order_item: false,
    show_order_item_dateless: false,
    show_order_time: false,
    show_order_table: false,
    show_order_waiting: false,
    display_text_size: 100,
    display_page_size: 10,
    display_refresh_individually: false,
    connect_printer: false,
  });
  customFontSizeClasses = ['font-size-125', 'font-size-150'];
  get sectionsFormArray(): FormArray {
    return this.form.controls['section'] as FormArray;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private ngrxStore: Store<State>,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('sections' in changes && this.sections) {
      this.sectionsFormArray.clear();
      this.addCheckboxesToForm();
    }
  }

  ngOnInit(): void {
    this.applySettings();
    if (this.kdsSettings?.display_text_size)
      this.applyFontSize(this.kdsSettings.display_text_size);
  }

  private addCheckboxesToForm(): void {
    this.sections?.forEach((i) => {
      this.sectionsDisplayed?.includes(i)
        ? this.sectionsFormArray.push(new FormControl(true))
        : this.sectionsFormArray.push(new FormControl(false));
    });
  }

  applyFontSize(textSize: number) {
    this.document.body.classList.remove(...this.customFontSizeClasses);
    if (!textSize || textSize === 100) return;
    this.document.body.classList.add(`font-size-${textSize}`);
  }

  applySettings(): void {
    const data: Partial<typeof this.form.value> = {};
    for (const field in this.form.controls) {
      data[field as keyof typeof this.form.value] = this.kdsSettings?.[
        field as keyof KdsSettings
      ] as any;
    }
    this.form.patchValue(data);
  }

  checkboxClicked({ source: { name }, checked }: MatCheckboxChange): void {
    const section = name as string;
    const selectedValues = this.getSelectedValues();
    if (!checked && selectedValues.length === 0) {
      // uncheck the last remaining
      this.sections
        ?.filter((sec) => sec !== name)
        .forEach((sec) => {
          this.ngrxStore.dispatch(fetchAllSectionTickets({ section: sec }));
        });
    } else if (checked && selectedValues.length === 1) {
      // check the first one
      this.sections
        ?.filter((sec) => sec !== name)
        .forEach((sec) => {
          this.ngrxStore.dispatch(clearSectionTickets({ section: sec }));
        });
    } else if (checked) {
      this.ngrxStore.dispatch(fetchAllSectionTickets({ section }));
    } else if (!checked) {
      this.ngrxStore.dispatch(clearSectionTickets({ section }));
    }
    this.ngrxStore.dispatch(setSectionsDisplayed({ sections: selectedValues }));
  }

  changePrintSettings({
    name,
    value,
  }: {
    name: string | null;
    value: boolean | number;
  }): void {
    this.ngrxStore.dispatch(
      changeKdsSettings({
        kdsSettings: { [name as string]: value },
      }),
    );
  }

  consumerInfoChange({
    name,
    value,
  }: {
    name: string | null;
    value: boolean | number;
  }): void {
    this.ngrxStore.dispatch(
      setKdsSettings({
        kdsSettings: { [name as string]: value },
      }),
    );
  }

  disconnectDevice(): void {
    this.ngrxStore.dispatch(disconnectPrinter());
  }

  reconnectDevice(): void {
    this.ngrxStore.dispatch(connectPrinter({ openFindPrinter: false }));
  }

  getSelectedValues = (): string[] =>
    this.form.controls.section.controls
      .map((val, idx) =>
        this.sections && val.value ? this.sections[idx] : undefined,
      )
      .filter((f) => !!f) as string[];

  isSectionChecked = (): number => this.getSelectedValues()?.length;

  showAllSections(): void {
    const selectedValues = this.getSelectedValues();
    this.sections
      ?.filter((sec) => !selectedValues.includes(sec))
      .forEach((section) => {
        this.ngrxStore.dispatch(fetchAllSectionTickets({ section }));
      });
    this.sectionsFormArray.controls.forEach((i) => i.patchValue(false));
    this.ngrxStore.dispatch(setSectionsDisplayed({ sections: [] }));
  }

  saveSectionChoice(): void {
    this.ngrxStore.dispatch(
      saveSectionsSelection({ sections: this.getSelectedValues() }),
    );
  }

  printerFindDevices(): void {
    this.ngrxStore.dispatch(findPrinter());
  }

  printerCutPaper(): void {
    this.ngrxStore.dispatch(cutPaper());
  }

  printerFeedPaper(): void {
    this.ngrxStore.dispatch(feedPaper());
  }

  saveSettings(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { section, ...data } = this.form.value;
    const kdsSettings = Object.assign({}, data) as Partial<KdsSettings>;
    this.ngrxStore.dispatch(changeKdsSettings({ kdsSettings }));
  }

  textSizeChanged(selectedValue: number) {
    this.applyFontSize(selectedValue);
    this.consumerInfoChange({
      name: 'display_text_size',
      value: selectedValue,
    });
  }

  pageSizeChanges(selectedValue: number) {
    this.consumerInfoChange({
      name: 'display_page_size',
      value: selectedValue,
    });
    this.ngrxStore.dispatch(fetchAllSectionTickets({}));
  }
}
