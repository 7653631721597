<mat-card
  class="ticket mat-elevation-z2"
  data-id="{ids}"
  [class.reservation-ticket]="serviceStatus === 1"
  [class.order-ticket]="serviceStatus === 2"
  [class.done-ticket]="serviceStatus === 4"
  [class.expedite-ticket]="expedite"
  [class.vip-ticket]="vip"
>
  @if (expedite || vip) {
    <div class="ticket-icons">
      @if (expedite) {
        <div class="header-icon expedite-icon">
          <mat-icon>notification_important</mat-icon>
        </div>
      }
      @if (vip) {
        <div class="header-icon vip-icon">
          <mat-icon>local_activity</mat-icon>
        </div>
      }
    </div>
  }

  <div class="ticket-header">
    <span class="place" [class.italic]="serviceStatus === 1">
      @if (kdsSettings?.show_order_table) {
        {{ tableNumber }}
      }
    </span>
    <span class="time">
      @if (timeDuration !== null && kdsSettings?.show_order_waiting) {
        <span class="time-since">
          {{ timeDuration }}{{ 'tickets.mins' | transloco }}
        </span>
      }
      @if (serviceStatus !== 1 && kdsSettings?.show_order_time) {
        <span class="ordered-time">
          {{ timestamp | date: 'HH:mm' }}
        </span>
      }
      <span class="ticket-extras">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          class="ticket-extras-button"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          @if (serviceStatus !== 1) {
            <button mat-menu-item (click)="undoTicketStatusEvent.emit(ids)">
              {{ 'tickets.menu.undo' | transloco }}
            </button>
          }
          <button mat-menu-item (click)="deleteTicketEvent.emit(ids)">
            {{ 'tickets.menu.delete-order' | transloco }}
          </button>
        </mat-menu>
      </span>
    </span>
  </div>

  <div class="ticket-content">
    <div class="customer-detail">
      @if (kdsSettings?.show_consumer_type) {
        {{ type }}
        <span class="dot">&middot; </span>
      }
      @if (kdsSettings?.show_consumer_name) {
        {{ customerName }}
      }
      @if (hasBirthday) {
        <span class="cake-icon primary-icon material-symbols-outlined">
          cake
        </span>
      }
      @if (kdsSettings?.show_consumer_name) {
        <span class="dot">&middot; </span>
      }
      @if (kdsSettings?.show_consumer_room && (roomFloor || room)) {
        {{ roomFloor }}/{{ room }}
        <span class="dot">&middot; </span>
      }
      @if (kdsSettings?.show_consumer_profile) {
        @if (consumerTexture) {
          <span class="consumer-texture">
            {{ 'tickets.texture.' + consumerTexture | transloco }}
          </span>
          <span class="dot">&middot; </span>
        }
        @if (consumerConsistency) {
          <span class="consumer-consistency">
            {{ 'tickets.consistency.' + consumerConsistency | transloco }}
          </span>
          <span class="dot">&middot; </span>
        }
        @if (consumerAllergies.length) {
          <span class="consumer-allergies">
            <span class="consumer-diet-label"
              >{{ 'tickets.dietary_profile.allergies' | transloco }}:</span
            >
            {{ consumerAllergies.join(', ') }}
          </span>
          <span class="dot">&middot; </span>
        }
        @if (consumerIntolerances.length) {
          <span class="consumer-intolerances">
            <span class="consumer-diet-label">{{
              'tickets.dietary_profile.intolerances' | transloco
            }}</span>
            {{ consumerIntolerances.join(', ') }}
          </span>
          <span class="dot">&middot; </span>
        }
        @if (consumerDiets.length) {
          <span class="consumer-diets">
            {{ consumerDiets.join(', ') }}
          </span>
          <span class="dot">&middot; </span>
        }
        @if (consumerPortionSize && consumerPortionSize !== 1) {
          <span class="consumer-portion-size">
            {{
              'tickets.portion-size.' + consumerPortionSize * 100 | transloco
            }}
          </span>
          <span class="dot">&middot;</span>
        }
        @for (data of customData | keyvalue; track data.key) {
          <span class="custom-data">
            {{ data.key }}
            @if (data.value !== true) {
              : {{ data.value }}
            }
          </span>
          <span class="dot">&middot;</span>
        }
      }
    </div>
    <hr class="dashed-line" />
    <div class="item-detail">
      <span class="order-items">
        @if (
          ((!kdsSettings?.show_order_item && datedMenu) ||
            (!kdsSettings?.show_order_item_dateless && !datedMenu)) &&
          itemOption !== 0
        ) {
          @if (quantity[0] > 1) {
            <span class="quantity">{{ quantity[0] }} &times;</span>
          }
          <span class="order-item">
            @if (specialOrder) {
              <span class="special-order">“</span>
              <span class="item-name">
                {{ item[0] }}
              </span>
              <span class="special-order">”</span>
            } @else {
              {{ 'tickets.item-option' | transloco }} {{ itemOption }}
            }
          </span>
          @for (variant of variants; track variant) {
            <span class="item-variant">
              @if (variant) {
                {{ variant }}
              }
            </span>
          }
        }
        @for (itemName of item; track itemName; let idx = $index) {
          <span
            class="order-item"
            [class.highlight-item]="
              (!samePortionSize && portionSize[idx] !== 1) ||
              (!sameTexture && texture[idx]) ||
              (!sameConsistency && consistency[idx])
            "
          >
            @if (
              (kdsSettings?.show_order_item && datedMenu) ||
              (kdsSettings?.show_order_item_dateless && !datedMenu) ||
              itemOption === 0
            ) {
              <div class="order-item-name">
                @if (quantity[idx] > 1) {
                  <span class="quantity"
                    >{{ quantity[idx] }}
                    &times;&nbsp;
                  </span>
                }
                @if (specialOrder) {
                  <span class="special-order">“</span>
                }
                <span class="item-name">
                  {{ itemName }}
                  @if (variants[idx]) {
                    <span class="item-variant">: {{ variants[idx] }}</span>
                  }
                </span>
                @if (specialOrder) {
                  <span class="special-order">”</span>
                }
              </div>
            }
            @if (description[idx]) {
              <span class="description"> ({{ description[idx] }})</span>
            }
            @if (!samePortionSize && portionSize[idx] !== 1) {
              <span class="item-diet-info">
                {{
                  'tickets.portion-size.' + portionSize[idx] * 100 | transloco
                }}</span
              >
            }
            @if (!sameTexture && texture[idx]) {
              <span class="item-diet-info">
                {{ 'tickets.texture.' + texture[idx] | transloco }}</span
              >
            }
            @if (!sameConsistency && consistency[idx]) {
              <span class="item-diet-info">
                {{
                  'tickets.consistency.' + consistency[idx] | transloco
                }}</span
              >
            }
          </span>
        }
        @if (
          (samePortionSize && portionSize[0] !== 1) ||
          (sameTexture && texture[0]) ||
          (sameConsistency && consistency[0])
        ) {
          <hr class="dashed-line" />
        }
        @if (diets.length && diets[0]) {
          <span class="item-diet-info"> {{ diets[0] }}</span>
        }
        @if (samePortionSize && portionSize[0] !== 1) {
          <span class="item-diet-info">
            {{
              'tickets.portion-size.' + portionSize[0] * 100 | transloco
            }}</span
          >
        }
        @if (sameTexture && texture[0]) {
          <span class="item-diet-info">
            {{ 'tickets.texture.' + texture[0] | transloco }}</span
          >
        }
        @if (sameConsistency && consistency[0]) {
          <span class="item-diet-info">
            {{ 'tickets.consistency.' + consistency[0] | transloco }}</span
          >
        }
      </span>
    </div>
  </div>

  @if (serviceStatus !== 4 || kdsSettings?.connect_printer) {
    <div class="ticket-footer">
      @if (kdsSettings?.connect_printer) {
        <button
          mat-icon-button
          [ngClass]="itemClass"
          (click)="printTicketEvent.emit()"
        >
          <mat-icon [class.print-icon-red]="!printerConnected">{{
            printerConnected ? 'print' : 'print_disabled'
          }}</mat-icon>
        </button>
      }
      @if (serviceStatus !== 4) {
        <button
          mat-icon-button
          [ngClass]="itemClassNext"
          (click)="updateTicketStatusEvent.emit(ids)"
        >
          <mat-icon>{{ itemIcon }}</mat-icon>
        </button>
      }
    </div>
  }
</mat-card>
