import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  extractFormErrors,
  flattenObject,
} from 'src/app/shared/utils.functions';

import * as SharedActions from './shared.actions';

@Injectable()
export class SharedEffects {
  showSnackbarMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.showSnackbarMessage),
        switchMap(({ errorCode, message, snackClass, button, duration }) => {
          if (message && message !== `<`) {
            this.snackBar.open(message, button, {
              duration: duration ?? 3000,
              panelClass: snackClass ?? 'success-snack',
            });
          }
          if (errorCode === 0)
            this.snackBar.open(this.offlineErrorMessage, '', {
              duration: duration ?? 3000,
              panelClass: 'error-snack',
            });
          if (errorCode && [500, 502].includes(errorCode))
            this.snackBar.open(this.serverErrorMessage, '', {
              duration: duration ?? 3000,
              panelClass: 'error-snack',
            });
          return [EMPTY];
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  handleHttpError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.handleHttpError),
      switchMap(({ error, formId, forceSnackbar }) => {
        if (!error?.error) return EMPTY;
        if ([500, 502].includes(error.error.status)) {
          return [
            SharedActions.showSnackbarMessage({
              message: this.serverErrorMessage,
              snackClass: 'error-snack',
            }),
          ];
        }
        const errors = extractFormErrors(error.error.error);
        if (error.error.status === 400 && errors && formId && !forceSnackbar) {
          const actionsToDispatch = [];
          if (Object.keys(errors).length > 1 || !errors?.non_field_errors) {
            const { non_field_errors, ...fieldErrors } = errors;
            actionsToDispatch.push(
              SharedActions.setFieldErrors({
                payload: fieldErrors as { [key: string]: string },
              }),
            );
          }
          if (errors.non_field_errors) {
            actionsToDispatch.push(
              SharedActions.setFormErrors({
                error: { form_id: formId, message: errors.non_field_errors[0] },
              }),
            );
          }
          return actionsToDispatch as [];
        }
        const flatObj = flattenObject(error.error.error);
        const firstErrorMessage =
          typeof flatObj === 'string' ? flatObj : Object.values(flatObj)[0];
        if (firstErrorMessage)
          return [
            SharedActions.showSnackbarMessage({
              message: firstErrorMessage,
              snackClass: 'error-snack',
            }),
          ];
        return EMPTY;
      }),
      catchError(() => EMPTY),
    ),
  );

  private readonly serverErrorMessageKey = 'shared.errors.server-error';
  private serverErrorMessage!: string;
  private readonly offlineErrorMessageKey = 'shared.errors.offline';
  private offlineErrorMessage!: string;

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private transloco: TranslocoService,
  ) {
    this.getServerErrorMessage();
    this.transloco.langChanges$.subscribe(() => [
      this.getOfflineErrorMessage(),
      this.getServerErrorMessage(),
    ]);
  }

  getOfflineErrorMessage(): void {
    this.transloco
      .selectTranslate(this.offlineErrorMessageKey)
      .subscribe((msg) => (this.offlineErrorMessage = msg));
  }

  getServerErrorMessage(): void {
    this.transloco
      .selectTranslate(this.serverErrorMessageKey)
      .subscribe((msg) => (this.serverErrorMessage = msg));
  }
}
