function e(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var t = e(new class {
  grayscale(e) {
    for (let t = 0; t < e.data.length; t += 4) {
      const s = .299 * e.data[t] + .587 * e.data[t + 1] + .114 * e.data[t + 2];
      e.data.fill(s, t, t + 3);
    }
    return e;
  }
  threshold(e, t) {
    for (let s = 0; s < e.data.length; s += 4) {
      const a = .299 * e.data[s] + .587 * e.data[s + 1] + .114 * e.data[s + 2] < t ? 0 : 255;
      e.data.fill(a, s, s + 3);
    }
    return e;
  }
  bayer(e, t) {
    const s = [[15, 135, 45, 165], [195, 75, 225, 105], [60, 180, 30, 150], [240, 120, 210, 90]];
    for (let a = 0; a < e.data.length; a += 4) {
      const o = .299 * e.data[a] + .587 * e.data[a + 1] + .114 * e.data[a + 2],
        i = a / 4 % e.width,
        n = Math.floor(a / 4 / e.width),
        r = Math.floor((o + s[i % 4][n % 4]) / 2) < t ? 0 : 255;
      e.data.fill(r, a, a + 3);
    }
    return e;
  }
  floydsteinberg(e) {
    const t = e.width,
      s = new Uint8ClampedArray(e.width * e.height);
    for (let t = 0, a = 0; a < e.data.length; t++, a += 4) s[t] = .299 * e.data[a] + .587 * e.data[a + 1] + .114 * e.data[a + 2];
    for (let a = 0, o = 0; o < e.data.length; a++, o += 4) {
      const i = s[a] < 129 ? 0 : 255,
        n = Math.floor((s[a] - i) / 16);
      e.data.fill(i, o, o + 3), s[a + 1] += 7 * n, s[a + t - 1] += 3 * n, s[a + t] += 5 * n, s[a + t + 1] += 1 * n;
    }
    return e;
  }
  atkinson(e) {
    const t = e.width,
      s = new Uint8ClampedArray(e.width * e.height);
    for (let t = 0, a = 0; a < e.data.length; t++, a += 4) s[t] = .299 * e.data[a] + .587 * e.data[a + 1] + .114 * e.data[a + 2];
    for (let a = 0, o = 0; o < e.data.length; a++, o += 4) {
      const i = s[a] < 129 ? 0 : 255,
        n = Math.floor((s[a] - i) / 8);
      e.data.fill(i, o, o + 3), s[a + 1] += n, s[a + 2] += n, s[a + t - 1] += n, s[a + t] += n, s[a + t + 1] += n, s[a + 2 * t] += n;
    }
    return e;
  }
}());
var s = e(new class {
  flatten(e, t) {
    for (let s = 0; s < e.data.length; s += 4) {
      const a = e.data[s + 3],
        o = 255 - a;
      e.data[s] = (a * e.data[s] + o * t[0]) / 255, e.data[s + 1] = (a * e.data[s + 1] + o * t[1]) / 255, e.data[s + 2] = (a * e.data[s + 2] + o * t[2]) / 255, e.data[s + 3] = 255;
    }
    return e;
  }
}());
const a = {
    ascii: {
      name: "ASCII",
      languages: ["en"],
      value: new Array(256).fill(1, 0, 128).map((e, t) => t)
    },
    cp437: {
      name: "USA, Standard Europe",
      languages: ["en"],
      extends: "ascii",
      value: [[, 9786, 9787, 9829, 9830, 9827, 9824, 8226, 9688, 9675, 9689, 9794, 9792, 9834, 9835, 9788], [9658, 9668, 8597, 8252, 182, 167, 9644, 8616, 8593, 8595, 8594, 8592, 8735, 8596, 9650, 9660],,,,,, [,,,,,,,,,,,,,,, 8962], [199, 252, 233, 226, 228, 224, 229, 231, 234, 235, 232, 239, 238, 236, 196, 197], [201, 230, 198, 244, 246, 242, 251, 249, 255, 214, 220, 162, 163, 165, 8359, 402], [225, 237, 243, 250, 241, 209, 170, 186, 191, 8976, 172, 189, 188, 161, 171, 187], [9617, 9618, 9619, 9474, 9508, 9569, 9570, 9558, 9557, 9571, 9553, 9559, 9565, 9564, 9563, 9488], [9492, 9524, 9516, 9500, 9472, 9532, 9566, 9567, 9562, 9556, 9577, 9574, 9568, 9552, 9580, 9575], [9576, 9572, 9573, 9561, 9560, 9554, 9555, 9579, 9578, 9496, 9484, 9608, 9604, 9612, 9616, 9600], [945, 223, 915, 960, 931, 963, 181, 964, 934, 920, 937, 948, 8734, 966, 949, 8745], [8801, 177, 8805, 8804, 8992, 8993, 247, 8776, 176, 8729, 183, 8730, 8319, 178, 9632, 160]]
    },
    cp720: {
      name: "Arabic",
      languages: ["ar"],
      extends: "cp437",
      value: [,,,,,,,, [65533, 65533,,, 65533,, 65533,,,,,,, 65533, 65533, 65533], [65533, 1617, 1618,, 164, 1600,,, 1569, 1570, 1571, 1572,, 1573, 1574, 1575], [1576, 1577, 1578, 1579, 1580, 1581, 1582, 1583, 1584, 1585, 1586, 1587, 1588, 1589,,,],,,, [1590, 1591, 1592, 1593, 1594, 1601,, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609, 1610], [, 1611, 1612, 1613, 1614, 1615, 1616,,,,,,,,,,]]
    },
    cp737: {
      name: "Greek",
      languages: ["el"],
      extends: "cp437",
      value: [,,,,,,,, [913, 914, 915, 916, 917, 918, 919, 920, 921, 922, 923, 924, 925, 926, 927, 928], [929, 931, 932, 933, 934, 935, 936, 937, 945, 946, 947, 948, 949, 950, 951, 952], [953, 954, 955, 956, 957, 958, 959, 960, 961, 963, 962, 964, 965, 966, 967, 968],,,, [969, 940, 941, 942, 970, 943, 972, 973, 971, 974, 902, 904, 905, 906, 908, 910], [911,,,, 938, 939,,,,,,,,,,,]]
    },
    cp771: {
      name: "Lithuanian KBL",
      languages: ["lt"],
      extends: "cp866",
      value: [,,,,,,,,,,,,, [,,,,,,,,,,,, 260, 261, 268, 269],, [280, 281, 278, 279, 302, 303, 352, 353, 370, 371, 362, 363, 381, 382,,,]]
    },
    cp772: {
      name: "Lithuanian LST 1284",
      languages: ["lt"],
      extends: "cp866",
      value: [,,,,,,,,,,, [,,,,, 260, 268, 280, 278,,,,, 302, 352,,], [,,,,,, 370, 362,,,,,,,, 381], [261, 269, 281, 279, 303, 353, 371, 363, 382,,,,,,,,],, [,, 8805, 8804, 8222, 8220, 247, 8776,,,,, 8319, 178,,,]]
    },
    cp774: {
      name: "Lithuanian LST 1283",
      languages: ["lt"],
      extends: "cp437",
      value: [,,,,,,,,,,, [,,,,, 260, 268, 280, 278,,,,, 302, 352,,], [,,,,,, 370, 362,,,,,,,, 381], [261, 269, 281, 279, 303, 353, 371, 363, 382,,,,,,,,], [, 946,,,,,,,,,,,,,, 8898], [,,,, 8222, 8220,,,,, 729,,,,,,]]
    },
    cp775: {
      name: "Baltic Rim",
      languages: ["et", "lt"],
      extends: "cp437",
      value: [,,,,,,,, [262,,, 257,, 291,, 263, 322, 275, 342, 343, 299, 377,,,], [,,, 333,, 290, 162, 346, 347,,, 248,, 216, 215, 164], [256, 298,, 379, 380, 378, 8221, 166, 169, 174,,,, 321,,,], [,,,,, 260, 268, 280, 278,,,,, 302, 352,,], [,,,,,, 370, 362,,,,,,,, 381], [261, 269, 281, 279, 303, 353, 371, 363, 382,,,,,,,,], [211,, 332, 323, 245, 213,, 324, 310, 311, 315, 316, 326, 274, 325, 8217], [173,, 8220, 190, 182, 167,, 8222,,,, 185, 179,,,,]]
    },
    cp850: {
      name: "Multilingual",
      languages: ["en"],
      extends: "cp437",
      value: [,,,,,,,,, [,,,,,,,,,,, 248,, 216, 215,,], [,,,,,,,,, 174,,,,,,,], [,,,,, 193, 194, 192, 169,,,,, 162, 165,,], [,,,,,, 227, 195,,,,,,,, 164], [240, 208, 202, 203, 200, 305, 205, 206, 207,,,,, 166, 204,,], [211,, 212, 210, 245, 213,, 254, 222, 218, 219, 217, 253, 221, 175, 180], [173,, 8215, 190, 182, 167,, 184,, 168,, 185, 179,,,,]]
    },
    cp851: {
      name: "Greek",
      languages: ["el"],
      extends: "cp437",
      value: [,,,,,,,, [,,,,,, 902,,,,,,, 904,, 905], [906, 65533, 908,,, 910,,, 911,,, 940,, 941, 942, 943], [970, 912, 972, 973, 913, 914, 915, 916, 917, 918, 919,, 920, 921,,,], [,,,,, 922, 923, 924, 925,,,,, 926, 927,,], [,,,,,, 928, 929,,,,,,,, 931], [932, 933, 934, 935, 936, 937, 945, 946, 947,,,,, 948, 949,,], [950, 951, 952, 953, 954, 955, 956, 957, 958, 959, 960, 961, 963, 962, 964, 180], [173,, 965, 966, 967, 167, 968, 184,, 168, 969, 971, 944, 974,,,]]
    },
    cp852: {
      name: "Latin 2",
      languages: ["hu", "pl", "cz"],
      extends: "cp850",
      value: [,,,,,,,, [,,,,, 367, 263,, 322,, 336, 337,, 377,, 262], [, 313, 314,,, 317, 318, 346, 347,,, 356, 357, 321,, 269], [,,,, 260, 261, 381, 382, 280, 281,, 378, 268, 351,,,], [,,,,,,, 282, 350,,,,, 379, 380,,], [,,,,,, 258, 259,,,,,,,,,], [273, 272, 270,, 271, 327,,, 283,,,,, 354, 366,,], [,,, 323, 324, 328, 352, 353, 340,, 341, 368,,, 355,,], [, 733, 731, 711, 728,,,,,, 729, 369, 344, 345,,,]]
    },
    cp853: {
      name: "Turkish",
      languages: ["tr"],
      extends: "cp437",
      value: [,,,,,,,, [,,,,,, 265,,,,,,,,, 264], [, 267, 266,,,,,, 304,,, 285,, 284, 215, 309], [,,,,,, 286, 287, 292, 293, 65533,, 308, 351,,,], [,,,,, 193, 194, 192, 350,,,,, 379, 380,,], [,,,,,, 348, 349,,,,,,,, 164], [65533, 65533, 202, 203, 200, 305, 205, 206, 207,,,,, 65533, 204,,], [211,, 212, 210, 288, 289,, 294, 295, 218, 219, 217, 364, 365, 65533, 180], [173, 65533, 8467, 329, 728, 167,, 184,, 168, 729, 65533, 179,,,,]]
    },
    cp855: {
      name: "Cyrillic",
      languages: ["bg"],
      extends: "cp437",
      value: [,,,,,,,, [1106, 1026, 1107, 1027, 1105, 1025, 1108, 1028, 1109, 1029, 1110, 1030, 1111, 1031, 1112, 1032], [1113, 1033, 1114, 1034, 1115, 1035, 1116, 1036, 1118, 1038, 1119, 1039, 1102, 1070, 1098, 1066], [1072, 1040, 1073, 1041, 1094, 1062, 1076, 1044, 1077, 1045, 1092, 1060, 1075, 1043,,,], [,,,,, 1093, 1061, 1080, 1048,,,,, 1081, 1049,,], [,,,,,, 1082, 1050,,,,,,,, 164], [1083, 1051, 1084, 1052, 1085, 1053, 1086, 1054, 1087,,,,, 1055, 1103,,], [1071, 1088, 1056, 1089, 1057, 1090, 1058, 1091, 1059, 1078, 1046, 1074, 1042, 1100, 1068, 8470], [173, 1099, 1067, 1079, 1047, 1096, 1064, 1101, 1069, 1097, 1065, 1095, 1063, 167,,,]]
    },
    cp857: {
      name: "Turkish",
      languages: ["tr"],
      extends: "cp437",
      value: [,,,,,,,, [,,,,,,,,,,,,, 305,,,], [,,,,,,,, 304,,, 248,, 216, 350, 351], [,,,,,, 286, 287,, 174,,,,,,,], [,,,,, 193, 194, 192, 169,,,,, 162, 165,,], [,,,,,, 227, 195,,,,,,,, 164], [186, 170, 202, 203, 200, 8364, 205, 206, 207,,,,, 166, 204,,], [211,, 212, 210, 245, 213,, 65533, 215, 218, 219, 217, 236, 255, 175, 180], [173,, 65533, 190, 182, 167,, 184,, 168,, 185, 179,,,,]]
    },
    cp858: {
      name: "Euro",
      languages: ["en"],
      extends: "cp850",
      value: [,,,,,,,,,,,,, [,,,,, 8364,,,,,,,,,,,],,,]
    },
    cp860: {
      name: "Portuguese",
      languages: ["pt"],
      extends: "cp437",
      value: [,,,,,,,, [,,,, 227,, 193,,, 202,, 205, 212,, 195, 194], [, 192, 200,, 245,, 218,, 204, 213,,,, 217,, 211], [,,,,,,,,, 210,,,,,,,],,,,,,]
    },
    cp861: {
      name: "Icelandic",
      languages: ["is"],
      extends: "cp437",
      value: [,,,,,,,, [,,,,,,,,,,, 208, 240, 222,,,], [,,,,, 254,, 221, 253,,, 248,, 216,,,], [,,,, 193, 205, 211, 218,,,,,,,,,],,,,,,]
    },
    cp862: {
      name: "Hebrew",
      languages: ["he"],
      extends: "cp437",
      value: [,,,,,,,, [1488, 1489, 1490, 1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502, 1503], [1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514,,,,,,],,,,,,,]
    },
    cp863: {
      name: "Canadian French",
      languages: ["fr"],
      extends: "cp437",
      value: [,,,,,,,, [,,,, 194,, 182,,,,,,, 8215, 192, 167], [, 200, 202,, 203, 207,,, 164, 212,,,, 217, 219,,], [166, 180,,, 168, 184, 179, 175, 206,,,,, 190,,,],,,,,,]
    },
    cp864: {
      name: "Arabic",
      languages: ["ar"],
      extends: "cp437",
      offset: 128,
      value: [176, 183, 8729, 8730, 9618, 9472, 9474, 9532, 9508, 9516, 9500, 9524, 9488, 9484, 9492, 9496, 946, 8734, 966, 177, 189, 188, 8776, 171, 187, 65271, 65272, 65533, 65533, 65275, 65276, 65533, 160, 173, 65154, 163, 164, 65156, 65533, 65533, 65166, 65167, 65173, 65177, 1548, 65181, 65185, 65189, 1632, 1633, 1634, 1635, 1636, 1637, 1638, 1639, 1640, 1641, 65233, 1563, 65201, 65205, 65209, 1567, 162, 65152, 65153, 65155, 65157, 65226, 65163, 65165, 65169, 65171, 65175, 65179, 65183, 65187, 65191, 65193, 65195, 65197, 65199, 65203, 65207, 65211, 65215, 65217, 65221, 65227, 65231, 166, 172, 247, 215, 65225, 1600, 65235, 65239, 65243, 65247, 65251, 65255, 65259, 65261, 65263, 65267, 65213, 65228, 65230, 65229, 65249, 65149, 1617, 65253, 65257, 65260, 65264, 65266, 65232, 65237, 65269, 65270, 65245, 65241, 65265, 9632, 65533]
    },
    cp865: {
      name: "Nordic",
      languages: ["sv", "dk"],
      extends: "cp437",
      value: [,,,,,,,,, [,,,,,,,,,,, 248,, 216,,,], [,,,,,,,,,,,,,,, 164],,,,,,]
    },
    cp866: {
      name: "Cyrillic 2",
      languages: ["ru"],
      extends: "cp437",
      value: [,,,,,,,, [1040, 1041, 1042, 1043, 1044, 1045, 1046, 1047, 1048, 1049, 1050, 1051, 1052, 1053, 1054, 1055], [1056, 1057, 1058, 1059, 1060, 1061, 1062, 1063, 1064, 1065, 1066, 1067, 1068, 1069, 1070, 1071], [1072, 1073, 1074, 1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082, 1083, 1084, 1085, 1086, 1087],,,, [1088, 1089, 1090, 1091, 1092, 1093, 1094, 1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103], [1025, 1105, 1028, 1108, 1031, 1111, 1038, 1118,,,,, 8470, 164,,,]]
    },
    cp869: {
      name: "Greek",
      languages: ["el"],
      extends: "cp437",
      value: [,,,,,,,, [65533, 65533, 65533, 65533, 65533, 65533, 902, 65533, 183, 172, 166, 8216, 8217, 904, 8213, 905], [906, 938, 908, 65533, 65533, 910, 939, 169, 911, 178, 179, 940,, 941, 942, 943], [970, 912, 972, 973, 913, 914, 915, 916, 917, 918, 919,, 920, 921,,,], [,,,,, 922, 923, 924, 925,,,,, 926, 927,,], [,,,,,, 928, 929,,,,,,,, 931], [932, 933, 934, 935, 936, 937, 945, 946, 947,,,,, 948, 949,,], [950, 951, 952, 953, 954, 955, 956, 957, 958, 959, 960, 961, 963, 962, 964, 900], [173,, 965, 966, 967, 167, 968, 901,, 168, 969, 971, 944, 974,,,]]
    },
    cp874: {
      name: "Thai",
      languages: ["th"],
      extends: "ascii",
      offset: 128,
      value: [8364, 65533, 65533, 65533, 65533, 8230, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 8216, 8217, 8220, 8221, 8226, 8211, 8212, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 160, 3585, 3586, 3587, 3588, 3589, 3590, 3591, 3592, 3593, 3594, 3595, 3596, 3597, 3598, 3599, 3600, 3601, 3602, 3603, 3604, 3605, 3606, 3607, 3608, 3609, 3610, 3611, 3612, 3613, 3614, 3615, 3616, 3617, 3618, 3619, 3620, 3621, 3622, 3623, 3624, 3625, 3626, 3627, 3628, 3629, 3630, 3631, 3632, 3633, 3634, 3635, 3636, 3637, 3638, 3639, 3640, 3641, 3642, 65533, 65533, 65533, 65533, 3647, 3648, 3649, 3650, 3651, 3652, 3653, 3654, 3655, 3656, 3657, 3658, 3659, 3660, 3661, 3662, 3663, 3664, 3665, 3666, 3667, 3668, 3669, 3670, 3671, 3672, 3673, 3674, 3675, 65533, 65533, 65533, 65533]
    },
    cp1001: {
      name: "Arabic",
      languages: ["ar"],
      extends: "cp437",
      value: [,,,,,,,, [1569, 1570, 1571, 1572, 1573, 65163, 1575, 1576, 65169, 1577, 1578, 65175, 1579, 65179, 1583, 1584], [1585, 1586, 1587, 65203, 65204, 1588, 65207, 65208, 1589, 65211, 65212, 1590, 65215, 65216, 1591, 1592], [1593, 65227, 1594, 65231, 1601, 65235, 1602, 65239, 1603, 1705, 1604, 65271, 65273, 65275, 9496, 1605], [65251, 1606, 65255, 1607, 65259, 1608, 1609, 1657, 65267, 9474, 1548, 1563, 1567, 65154, 65156, 65162], [, 1580, 65183, 1581, 65187, 1582, 65191, 65226, 65228, 65230, 65231, 65272, 65274, 65276, 65259, 65260], [1632, 65264, 9472, 65533, 1632, 1633, 1634, 1635, 1636, 1637, 1638, 1639, 1640, 1641, 65533, 65533], [65533, 65533, 65275, 65533, 65533, 65533, 65533, 9524,,,,,,,,,],,]
    },
    cp1098: {
      name: "Farsi",
      languages: ["fa"],
      extends: "cp437",
      value: [,, [,,,,, 65533,,,,, 65533,,,,,,],,,,,, [65533, 65533, 1548, 1563, 1567, 1611, 1570, 65154, 63738, 1575, 65166, 63739, 1569, 1571, 65156, 63737], [1572, 65163, 1576, 65169, 64342, 64344, 1578, 65175, 1579, 65179, 1580, 65183, 64378, 64380, 215, 1581], [65187, 1582, 65191, 1583, 1584, 1585, 1586, 64394, 1587, 65203, 1588, 65207, 1589, 65211,,,], [,,,,, 1590, 65215, 65217, 65219,,,,, 164, 65221,,], [,,,,,, 65223, 1593,,,,,,,, 65533], [65226, 65227, 65228, 1594, 65230, 65231, 65232, 1601, 65235,,,,, 1602, 65239,,], [64398, 65243, 64402, 64404, 1604, 65247, 1605, 65251, 1606, 65255, 1608, 1607, 65259, 65260, 64420, 64508], [173, 64509, 64510, 1600, 1632, 1633, 1634, 1635, 1636, 1637, 1638, 1639, 1640, 1641,,,]]
    },
    cp1125: {
      name: "Ukrainian",
      languages: ["uk"],
      extends: "cp866",
      value: [,,,,,,,,,,,,,,, [,, 1168, 1169, 1028, 1108, 1030, 1110, 1031, 1111, 247, 177,,,,,]]
    },
    cp3001: {
      name: "Estonian 1 or 1116",
      languages: ["et"],
      extends: "cp850",
      value: [,,,,,,,,,,,,, [353, 352,,,,,,,,,,,,,,,], [,,,,,,, 382, 381,,,,,,,,],,]
    },
    cp3002: {
      name: "Estonian 2",
      languages: ["et"],
      extends: "iso8859-1",
      value: [,,,,,,,, [65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533], [65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533], [32,,,,,,,,,,,,,,, 8254],,, [352,,,,,,,,,,,,,, 381,,],, [353,,,,,,,,,,,,,, 382,,]]
    },
    cp3011: {
      name: "Latvian 1",
      languages: ["lv"],
      extends: "cp437",
      value: [,,,,,,,,,,, [,,,,, 256,, 326,,,,,,,,,], [,,,,,, 257,,,,,,,,,,], [352,, 269, 268,,, 291, 298, 299,,,,, 363, 362,,],, [274, 275, 290, 311, 310, 316, 315, 382, 381,,,, 325, 353,,,]]
    },
    cp3012: {
      name: "Latvian 2 (modified 866)",
      languages: ["lv"],
      extends: "cp866",
      value: [,,,,,,,,,,, [,,,,, 256,, 326,,,,,, 332,,,], [,,,,,, 257,,,,,,,,,,], [352,, 269, 268,,, 291, 298, 299,,,,, 363, 362,,],, [274, 275, 290, 311, 310, 316, 315, 382, 381, 333,,, 325, 353,,,]]
    },
    cp3021: {
      name: "Bulgarian (MIK)",
      languages: ["bg"],
      extends: "cp866",
      value: [,,,,,,,,,,, [1088, 1089, 1090, 1091, 1092, 1093, 1094, 1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103], [,,,,,, 9571, 9553,,,,,,,, 9488], [9617, 9618, 9619, 9474, 9508, 8470, 167, 9559, 9565,,,,,,,,], [945, 223, 915, 960, 931, 963, 181, 964, 934, 920, 937, 948, 8734, 966, 949, 8745], [8801, 177, 8805, 8804, 8992, 8993, 247, 8776,,,,, 8319, 178,,,]]
    },
    cp3041: {
      name: "Maltese ISO 646",
      languages: ["mt"],
      extends: "ascii",
      value: [,,,,, [,,,,,,,,,,, 289, 380, 295,,,], [267,,,,,,,,,,,,,,,,], [,,,,,,,,,,, 288, 379, 294, 266,,],,,,,,,,,]
    },
    cp3840: {
      name: "Russian (modified 866)",
      languages: ["ru"],
      extends: "cp866",
      value: [,,,,,,,,,,,,,,, [8801, 177, 8805, 8804, 8992, 8993, 247, 8776,,,,, 8319, 178,,,]]
    },
    cp3841: {
      name: "Ghost",
      languages: ["ru"],
      extends: "cp437",
      offset: 128,
      value: [1171, 1241, 1105, 1110, 1111, 1112, 1181, 1257, 1118, 1199, 1277, 1224, 1209, 1207, 1108, 163, 1170, 1240, 1025, 1030, 1031, 1032, 1180, 1256, 1038, 1198, 1276, 1223, 1208, 1206, 1028, 1066, 32, 33, 34, 35, 164, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 1102, 1072, 1073, 1094, 1076, 1077, 1092, 1075, 1093, 1080, 1081, 1082, 1083, 1084, 1085, 1086, 1087, 1103, 1088, 1089, 1090, 1091, 1078, 1074, 1100, 1099, 1079, 1096, 1101, 1097, 1095, 1098, 1070, 1040, 1041, 1062, 1044, 1045, 1060, 1043, 1061, 1048, 1049, 1050, 1051, 1052, 1053, 1054, 1055, 1071, 1056, 1057, 1058, 1059, 1046, 1042, 1068, 1067, 1047, 1064, 1069, 1065, 1063, 8709]
    },
    cp3843: {
      name: "Polish (Mazovia)",
      languages: ["pl"],
      extends: "cp437",
      value: [,,,,,,,, [,,,,,, 261,,,,,,, 263,, 260], [280, 281, 322,,, 262,,, 346,,,, 321,, 347,,], [377, 379,, 211, 324, 323, 378, 380,,,,,,,,,],,,,,,]
    },
    cp3844: {
      name: "Czech (Kamenický)",
      languages: ["cz"],
      extends: "cp437",
      value: [,,,,,,,, [268,,, 271,, 270, 356, 269, 283, 282, 313, 205, 318, 314,, 193], [, 382, 381,,, 211, 367, 218, 253,,, 352, 317, 221, 344, 357], [,,,, 328, 327, 366, 212, 353, 345, 341, 340,, 167,,,],,,,,,]
    },
    cp3845: {
      name: "Hungarian (CWI-2)",
      languages: ["hu"],
      extends: "cp437",
      value: [,,,,,,,, [,,,,,,,,,,,,, 205,, 193], [,,, 337,, 211, 369, 218, 368,,,,,,,,], [,,,,,,, 336,,,,,,,,,],,,,,,]
    },
    cp3846: {
      name: "Turkish",
      languages: ["tr"],
      extends: "cp437",
      value: [,,,,,,,, [,,,,,,,,,,,,, 305,,,], [,,,,,,,, 304,,,,,, 350, 351], [,,,,,, 286, 287,,,,,,,,,],,,,,,]
    },
    cp3847: {
      name: "Brazil ABNT",
      languages: ["pt"],
      extends: "iso8859-1",
      value: [,,,,,,,,,,,,, [,,,,,,, 65533,,,,,,,,,],, [,,,,,,, 65533,,,,,,,,,]]
    },
    cp3848: {
      name: "Brazil ABICOMP",
      languages: ["pt"],
      extends: "ascii",
      value: [,,,,,,,,,, [160, 192, 193, 194, 195, 196, 199, 200, 201, 202, 203, 204, 205, 206, 205, 209], [210, 211, 212, 213, 214, 198, 217, 218, 219, 220, 376, 168, 163, 166, 167, 176], [161, 224, 225, 226, 227, 228, 231, 232, 233, 234, 235, 236, 237, 238, 239, 241], [242, 243, 244, 245, 246, 230, 249, 250, 251, 252, 255, 223, 170, 186, 191, 177],,,]
    },
    "iso8859-1": {
      name: "Latin 1",
      languages: ["en"],
      extends: "ascii",
      offset: 128,
      value: [128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255]
    },
    "iso8859-2": {
      name: "Latin 2",
      languages: ["hu", "pl", "cz"],
      extends: "iso8859-1",
      value: [,,,,,,,,,, [, 260, 728, 321,, 317, 346,,, 352, 350, 356, 377,, 381, 379], [, 261, 731, 322,, 318, 347, 711,, 353, 351, 357, 378, 733, 382, 380], [340,,, 258,, 313, 262,, 268,, 280,, 282,,, 270], [272, 323, 327,,, 336,,, 344, 366,, 368,,, 354,,], [341,,, 259,, 314, 263,, 269,, 281,, 283,,, 271], [273, 324, 328,,, 337,,, 345, 367,, 369,,, 355, 729]]
    },
    "iso8859-7": {
      name: "Greek",
      languages: ["el"],
      extends: "iso8859-1",
      value: [,,,,,,,,,, [, 8216, 8217,, 8364, 8367,,,,, 890,,,,, 8213], [,,,, 900, 901, 902,, 904, 905, 906,, 908,, 910, 911], [912, 913, 914, 915, 916, 917, 918, 919, 920, 921, 922, 923, 924, 925, 926, 927], [928, 929,, 931, 932, 933, 934, 935, 936, 937, 938, 939, 940, 941, 942, 943], [944, 945, 946, 947, 948, 949, 950, 951, 952, 953, 954, 955, 956, 957, 958, 959], [960, 961, 962, 963, 964, 965, 966, 967, 968, 969, 970, 971, 972, 973, 974,,]]
    },
    "iso8859-15": {
      name: "Latin 9",
      languages: ["fr"],
      extends: "iso8859-1",
      value: [,,,,,,,,,, [,,,, 8364,, 352,, 353,,,,,,,,], [,,,, 381,,,, 382,,,, 338, 339, 376,,],,,,,]
    },
    windows1250: {
      name: "Latin 2",
      languages: ["hu", "pl", "cz"],
      extends: "windows1252",
      value: [,,,,,,,, [,,, 65533,,,,, 65533,,,, 346, 356,, 377], [,,,,,,,, 65533,,,, 347, 357,, 378], [, 711, 728, 321,, 260,,,,, 350,,,,, 379], [,, 731, 322,,,,,, 261, 351,, 317, 733, 318, 380], [340,,, 258,, 313, 262,, 268,, 280,, 282,,, 270], [272, 323, 327,,, 336,,, 344, 366,, 368,,, 354,,], [341,,, 259,, 314, 263,, 269,, 281,, 283,,, 271], [273, 324, 328,,, 337,,, 345, 367,, 369,,, 355, 729]]
    },
    windows1251: {
      name: "Cyrillic",
      languages: ["ru"],
      extends: "windows1252",
      value: [,,,,,,,, [1026, 1027,, 1107,,,,, 8364,, 1033,, 1034, 1036, 1035, 1039], [1106,,,,,,,, 65533,, 1113,, 1114, 1116, 1115, 1119], [, 1038, 1118, 1032,, 1168,,, 1025,, 1028,,,,, 1031], [,, 1030, 1110, 1169,,,, 1105, 8470, 1108,, 1112, 1029, 1109, 1111], [1040, 1041, 1042, 1043, 1044, 1045, 1046, 1047, 1048, 1049, 1050, 1051, 1052, 1053, 1054, 1055], [1056, 1057, 1058, 1059, 1060, 1061, 1062, 1063, 1064, 1065, 1066, 1067, 1068, 1069, 1070, 1071], [1072, 1073, 1074, 1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082, 1083, 1084, 1085, 1086, 1087], [1088, 1089, 1090, 1091, 1092, 1093, 1094, 1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103]]
    },
    windows1252: {
      name: "Latin",
      languages: ["fr"],
      extends: "iso8859-1",
      value: [,,,,,,,, [8364, 65533, 8218, 402, 8222, 8230, 8224, 8225, 710, 8240, 352, 8249, 338, 65533, 381, 65533], [65533, 8216, 8217, 8220, 8221, 8226, 8211, 8212, 732, 8482, 353, 8250, 339, 65533, 382, 376],,,,,,,]
    },
    windows1253: {
      name: "Greek",
      languages: ["el"],
      extends: "windows1252",
      value: [,,,,,,,, [,,,,,,,, 65533,, 65533,, 65533,, 65533,,], [,,,,,,,, 65533,, 65533,, 65533,, 65533, 65533], [, 901, 902,,,,,,,, 65533,,,,, 8213], [,,,, 900,,,, 904, 905, 906,, 908,, 910, 911], [912, 913, 914, 915, 916, 917, 918, 919, 920, 921, 922, 923, 924, 925, 926, 927], [928, 929, 65533, 931, 932, 933, 934, 935, 936, 937, 938, 939, 940, 941, 942, 943], [944, 945, 946, 947, 948, 949, 950, 951, 952, 953, 954, 955, 956, 957, 958, 959], [960, 961, 962, 963, 964, 965, 966, 967, 968, 969, 970, 971, 972, 973, 974, 65533]]
    },
    windows1254: {
      name: "Turkish",
      languages: ["tr"],
      extends: "windows1252",
      value: [,,,,,,,, [,,,,,,,,,,,,,, 65533,,], [,,,,,,,,,,,,,, 65533,,],,,, [286,,,,,,,,,,,,, 304, 350,,],, [287,,,,,,,,,,,,, 305, 351,,]]
    },
    windows1255: {
      name: "Hebrew",
      languages: ["he"],
      extends: "windows1252",
      value: [,,,,,,,, [,,,,,,,,,, 65533,, 65533,, 65533,,], [,,,,,,,,,, 65533,, 65533,, 65533, 65533], [,,,, 8362,,,,,, 215,,,,,,], [,,,,,,,,,, 247,,,,,,], [1456, 1457, 1458, 1459, 1460, 1461, 1462, 1463, 1464, 1465, 65533, 1467, 1468, 1469, 1470, 1471], [1472, 1473, 1474, 1475, 1520, 1521, 1522, 1523, 1524, 65533, 65533, 65533, 65533, 65533, 65533, 65533], [1488, 1489, 1490, 1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502, 1503], [1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514, 65533, 65533, 8206, 8207, 65533]]
    },
    windows1256: {
      name: "Arabic",
      languages: ["ar"],
      extends: "windows1252",
      value: [,,,,,,,, [, 1662,,,,,,,,, 1657,,, 1670, 1688, 1672], [1711,,,,,,,, 1705,, 1681,,, 8204, 8205, 1722], [, 1548,,,,,,,,, 1726,,,,,,], [,,,,,,,,,, 1563,,,,, 1567], [1729, 1569, 1570, 1571, 1572, 1573, 1574, 1575, 1576, 1577, 1578, 1579, 1580, 1581, 1582, 1583], [1584, 1585, 1586, 1587, 1588, 1589, 1590,, 1591, 1592, 1593, 1594, 1600, 1601, 1602, 1603], [, 1604,, 1605, 1606, 1607, 1608,,,,,, 1609, 1610,,,], [1611, 1612, 1613, 1614,, 1615, 1616,, 1617,, 1618,,, 8206, 8207, 1746]]
    },
    windows1257: {
      name: "Baltic Rim",
      languages: ["et", "lt"],
      extends: "windows1252",
      value: [,,,,,,,, [,,, 65533,,,,, 65533,, 65533,, 65533, 168, 711, 184], [,,,,,,,, 65533,, 65533,, 65533, 175, 731, 65533], [, 65533,,,, 65533,,, 216,, 342,,,,, 198], [,,,,,,,, 248,, 343,,,,, 230], [260, 302, 256, 262,,, 280, 274, 268,, 377, 278, 290, 310, 298, 315], [352, 323, 325,, 332,,,, 370, 321, 346, 362,, 379, 381,,], [261, 303, 257, 263,,, 281, 275, 269,, 378, 279, 291, 311, 299, 316], [353, 324, 326,, 333,,,, 371, 322, 347, 363,, 380, 382, 729]]
    },
    windows1258: {
      name: "Vietnamese",
      languages: ["vi"],
      extends: "windows1252",
      value: [,,,,,,,, [,,,,,,,,,, 65533,,,, 65533,,], [,,,,,,,,,, 65533,,,, 65533,,],,, [,,, 258,,,,,,,,, 768,,,,], [272,, 777,,, 416,,,,,,,, 431, 771,,], [,,, 259,,,,,,,,, 769,,,,], [273,, 803,,, 417,,,,,,,, 432, 8363,,]]
    },
    rk1048: {
      name: "Kazakh",
      languages: ["kk"],
      extends: "windows1251",
      value: [,,,,,,,, [,,,,,,,,,,,,, 1178, 1210,,], [,,,,,,,,,,,,, 1179, 1211,,], [, 1200, 1201, 1240,, 1256,,,,, 1170,,,,, 1198], [,,,, 1257,,,,,, 1171,, 1241, 1186, 1187, 1199],,,,,]
    },
    thai11: {
      name: "Thai (Charcter Code 11)",
      languages: ["th"],
      extends: "thai13",
      value: [,,,,,,,,, [,,,,,,,,, 9484, 9488, 9492, 9496, 9474, 9500, 9508],,,, [,,,,,,,,,,, 9472, 9524, 9516, 9532,,],, [,,,,,,,,,,, 3658, 3650, 3651, 3652, 65533]]
    },
    thai13: {
      name: "Thai (Charcter Code 13)",
      languages: ["th"],
      extends: "cp874",
      value: [,,,,,,,, [65533,,,,, 65533,,,,,,,,,,,], [, 65533, 65533, 65533, 65533, 65533, 65533, 65533,,,,,,,,,],,,, [,,,,,,,,,,, 3619,,, 3648,,],, [,,,,,,,,,,,, 8592, 8593, 8594, 8595]]
    },
    thai14: {
      name: "Thai (Charcter Code 14)",
      languages: ["th"],
      extends: "cp874",
      value: [,,,,,,,, [9484, 9488, 9492, 9496, 9474, 9472, 9500, 9508, 9524, 9516, 9532, 9608,,,,,], [3619, 3634, 65533, 3632, 65533, 65533, 65533, 65533,,,,,,,, 3648],,,,,, [,,,,,,,,,, 3675, 65533,,,, 3652]]
    },
    thai16: {
      name: "Thai (Charcter Code 16)",
      languages: ["th"],
      extends: "thai18",
      value: [,,,,,,,,, [3650, 3651,,,,,,,,,,,,,,,],,,,,,,]
    },
    thai18: {
      name: "Thai (Charcter Code 18)",
      languages: ["th"],
      extends: "thai14",
      value: [,,,,,,,, [,,,,,,,,,,,, 8592, 8593, 8594, 8595],,,,,, [,,,,,,,,,,,,,, 3652,,], [,,,,,,,,,,,,,,, 65533]]
    },
    thai42: {
      name: "Thai (Charcter Code 42)",
      languages: ["th"],
      extends: "thai18",
      value: [,,,,,,,,, [3664, 3665, 3666, 3667, 3668, 3669, 3670, 3671, 3672, 3673, 3587, 3589, 3619, 3634, 3652,,], [,,, 3588, 3590, 3591, 3592, 3593, 3594, 3595, 3596, 3597, 3598, 3599, 3600, 3601], [3602, 3603, 3604, 3605, 3606, 3607, 3608, 3609, 3610, 3611, 3612, 3613, 3614, 3615, 3616, 3617], [3618, 3619, 3620, 3621, 3623, 3624, 3625, 3626, 3627, 3628, 3629, 3630, 3632, 3622, 3634, 3635], [3648, 3649, 3650, 3651, 3652, 3654, 3631, 3640, 3641, 3636, 3637, 3638, 3639, 3658, 3661, 3655], [3656, 3657, 3658, 3659, 3660, 65533, 65533, 65533, 65533, 65533, 65533, 3632, 65533, 65533, 65533, 65533], [65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533, 65533,,,,, 160]]
    },
    tcvn3: {
      name: "Vietnamese (VSCII3 / TCVN3)",
      languages: ["vi"],
      extends: "ascii",
      value: [,,,,,,,,,, [,,,,,,,, 259, 226, 234, 244, 417, 432, 273,,], [,,,,, 224, 7843, 227, 225, 7841,, 7857, 7859, 7861, 7855,,], [,,,,,, 7863, 7847, 7849, 7851, 7845, 7853, 232,, 7867, 7869], [233, 7865, 7873, 7875, 7877, 7871, 7879, 236, 7881,,,, 297, 237, 7883, 242], [, 7887, 245, 243, 7885, 7891, 7893, 7895, 7889, 7897, 7901, 7903, 7905, 7899, 7907, 249], [, 7911, 361, 250, 7909, 7915, 7917, 7919, 7913, 7921, 7923, 7927, 7929, 253, 7925,,]]
    },
    tcvn3capitals: {
      name: "Vietnamese (VSCII3 / TCVN3 Capitals)",
      languages: ["vi"],
      extends: "ascii",
      value: [,,,,,,,,,, [, 258, 194,,,,, 208,,, 202, 212, 416, 431,,,], [,,,,, 192, 7842, 195, 193, 7840,, 7856, 7858, 7860, 7854,,], [,,,,,, 7862, 7846, 7848, 7850, 7844, 7852, 200,, 7866, 7868], [201, 7864, 7872, 7874, 7876, 7870, 7878, 204, 7880,,,, 296, 205, 7882, 210], [, 7886, 213, 211, 7884, 7890, 7892, 7894, 7888, 7896, 7900, 7902, 7904, 7898, 7906, 217], [, 7910, 360, 218, 7908, 7914, 7916, 7918, 7912, 7920, 7922, 7926, 7928, 221, 7924,,]]
    },
    viscii: {
      name: "Vietnamese (VISCII)",
      languages: ["vi"],
      extends: "ascii",
      value: [[,, 7858,,, 7860, 7850,,,,,,,,,,], [,,,, 7926,,,,, 7928,,,,, 7924,,],,,,,,, [7840, 7854, 7856, 7862, 7844, 7846, 7848, 7852, 7868, 7864, 7870, 7872, 7874, 7876, 7878, 7888], [7890, 7892, 7894, 7896, 7906, 7898, 7900, 7902, 7882, 7886, 7884, 7880, 7910, 360, 7908, 7922], [213, 7855, 7857, 7863, 7845, 7847, 7849, 7853, 7869, 7865, 7871, 7873, 7875, 7877, 7879, 7889], [7891, 7893, 7895, 7904, 416, 7897, 7901, 7903, 7883, 7920, 7912, 7914, 7916, 417, 7899, 431], [192, 193, 194, 195, 7842, 258, 7859, 7861, 200, 201, 202, 7866, 204, 205, 296, 7923], [272, 7913, 210, 211, 212, 7841, 7927, 7915, 7917, 217, 218, 7929, 7925, 221, 7905, 432], [224, 225, 226, 227, 7843, 259, 7919, 7851, 232, 233, 234, 7867, 236, 237, 297, 7881], [273, 7921, 242, 243, 244, 245, 7887, 7885, 7909, 249, 250, 361, 7911, 253, 7907, 7918]]
    },
    khmer: {
      name: "Khmer",
      languages: ["km"],
      extends: "ascii",
      value: [,,,,,,,, [6016, 6017, 6016, 6019, 6020, 6021, 6022, 6023, 6024, 6025, 6026, 6027, 6028, 6029, 6030, 6031], [6032, 6033, 6032, 6035, 6036, 6037, 6038, 6039, 6040, 6041, 6042, 6043, 6044, 6045, 6046, 6047], [6048, 6049, 6048, 6051, 6052, 6053, 6054, 6055, 6056, 6057, 6058, 6059, 6060, 6061, 6062, 6063], [6064, 6065, 6064, 6067, 6068, 6069, 6070, 6071, 6072, 6073, 6074, 6075, 6076, 6077, 6078, 6079], [6080, 6081, 6080, 6083, 6084, 6085, 6086, 6087, 6088, 6089, 6090, 6091, 6092, 6093, 6094, 6095], [6096, 6097, 6096, 6099, 6100, 6101, 6102, 6103, 6104, 6105, 6106, 6107, 6108, 6109,,,], [6112, 6113, 6112, 6115, 6116, 6117, 6118, 6119, 6120, 6121,,,,,,,], [6128, 6129, 6128, 6131, 6132, 6133, 6134,,,,,,,,,,]]
    },
    latvian: {
      name: "Latvian (based on cp3012)",
      languages: ["lv"],
      extends: "cp3012",
      value: [,,,,,,,,,,,,, [,,,, 298, 299,,,,,,,,,,,],,,]
    },
    "epson/katakana": {
      name: "Katakana",
      languages: ["ja"],
      extends: "cp437",
      offset: 128,
      value: [9601, 9602, 9603, 9604, 9605, 9606, 9607, 9608, 9615, 9614, 9613, 9612, 9611, 9610, 9609, 9532, 9524, 9516, 9508, 9500, 9620, 9472, 9474, 9621, 9484, 9488, 9492, 9496, 9581, 9582, 9584, 9583, 32, 65377, 65378, 65379, 65380, 65381, 65382, 65383, 65384, 65385, 65386, 65387, 65388, 65389, 65390, 65391, 65392, 65393, 65394, 65395, 65396, 65397, 65398, 65399, 65400, 65401, 65402, 65403, 65404, 65405, 65406, 65407, 65408, 65409, 65410, 65411, 65412, 65413, 65414, 65415, 65416, 65417, 65418, 65419, 65420, 65421, 65422, 65423, 65424, 65425, 65426, 65427, 65428, 65429, 65430, 65431, 65432, 65433, 65434, 65435, 65436, 65437, 65438, 65439, 9552, 9566, 9578, 9569, 9698, 9699, 9701, 9700, 9824, 9829, 9830, 9827, 9679, 9675, 9585, 9586, 9587, 20870, 24180, 26376, 26085, 26178, 20998, 31186, 12306, 24066, 21306, 30010, 26449, 20154, 9618, 160]
    },
    "epson/iso8859-2": {
      name: "Latin 2 modified with box drawing characters",
      languages: ["hu", "pl", "cz"],
      extends: "iso8859-2",
      value: [,,,,,,,, [9617, 9618, 9619, 9474, 9508, 9496, 9484, 9608, 169, 9571, 9553, 9559, 9565, 162, 165, 9488], [9492, 9524, 9516, 9500, 9472, 9532, 9604, 9600, 9562, 9556, 9577, 9574, 9568, 9552, 9580, 174],,,,,,,]
    },
    "star/standard": {
      name: "Standard",
      languages: ["en"],
      extends: "cp437",
      offset: 128,
      value: [160, 129904, 129910, 129905, 129911, 129906, 129912, 129907, 129913, 129908, 129914, 129909, 9500, 129915, 129916, 129918, 129919, 129917, 9624, 9623, 9622, 9629, 9585, 9586, 9701, 9700, 9484, 9524, 9516, 9508, 9830, 9587, 196, 214, 220, 223, 167, 170, 186, 65533, 162, 189, 65533, 65533, 165, 188, 256, 235, 233, 232, 275, 234, 239, 237, 236, 299, 238, 246, 243, 242, 333, 244, 252, 250, 249, 363, 251, 231, 191, 65533, 65533, 274, 8580, 161, 197, 248, 216, 228, 225, 224, 257, 226, 176, 8451, 8457, 937, 181, 931, 963, 65533, 65533, 967, 8734, 177, 247, 960, 9612, 9604, 9600, 9616, 9532, 9496, 9492, 9488, 9608, 9618, 9162, 8592, 8593, 8594, 8595, 9581, 9161, 129923, 65533, 9601, 9613, 65533, 129928, 9626, 9630, 9698, 9699, 129840, 9208, 9584, 9583, 9582]
    },
    "star/katakana": {
      name: "Katakana",
      languages: ["ja"],
      extends: "star/standard",
      value: [,,,,,,,,,, [32, 65377, 65378, 65379, 65380, 65381, 65382, 65383, 65384, 65385, 65386, 65387, 65388, 65389, 65390, 65391], [65392, 65393, 65394, 65395, 65396, 65397, 65398, 65399, 65400, 65401, 65402, 65403, 65404, 65405, 65406, 65407], [65408, 65409, 65410, 65411, 65412, 65413, 65414, 65415, 65416, 65417, 65418, 65419, 65420, 65421, 65422, 65423], [65424, 65425, 65426, 65427, 65428, 65429, 65430, 65431, 65432, 65433, 65434, 65435, 65436, 65437, 65438, 65439],,,]
    },
    "star/cp874": {
      name: "Thai",
      languages: ["th"],
      extends: "cp874",
      value: [,,,,,,,,, [,,,,, 9474, 9472, 9532, 9484, 9488, 9492, 9496, 9500, 9516, 9508, 9524],,,, [,,,,,,,,,,, 3657, 3658, 3659, 3660,,],, [,,,,,,,,,,,, 162, 172, 166,,]]
    },
    "star/cp928": {
      name: "Greek",
      languages: ["el"],
      extends: "cp437",
      value: [,,,,,,,,,, [160, 8216, 8217, 163, 65533, 65533, 166, 167, 168, 169, 65533, 171, 172, 173, 65533, 8213], [176, 177, 178, 179, 900, 901, 902, 183, 904, 905, 906, 187, 908, 189, 910, 911], [912, 913, 914, 915, 916, 917, 918, 919, 920, 921, 922, 923, 924, 925, 926, 927], [928, 929, 65533, 931, 932, 933, 934, 935, 936, 937, 938, 939, 940, 941, 942, 943], [944, 945, 946, 947, 948, 949, 950, 951, 952, 953, 954, 955, 956, 957, 958, 959], [960, 961, 962, 963, 964, 965, 966, 967, 968, 969, 970, 971, 972, 973, 974, 65533]]
    },
    "bixolon/cp866": {
      name: "Cyrillic 2 (modified with euro sign)",
      languages: ["ru"],
      extends: "cp866",
      value: [,,,,,,,,,,,,,,, [,,,, 9093, 1055, 8801, 9824, 8364,,,,,,,,]]
    },
    "bixolon/hebrew": {
      name: "Hebrew",
      languages: ["he"],
      extends: "cp437",
      value: [,,,,,,,,,, [32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47], [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63], [64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79], [80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95], [1488, 1489, 1490, 1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502, 1503], [1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514, 123, 124, 125, 126,,]]
    },
    "xprinter/hebrew": {
      name: "Hebrew (based on CP862 minus CP437)",
      languages: ["he"],
      extends: "ascii",
      value: [,,,,,,,, [1488, 1489, 1490, 1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502, 1503], [1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514,,,,,,],,,,,,,]
    },
    "pos8360/hebrew": {
      name: "Hebrew (based on cp862 with repeated characters)",
      languages: ["he"],
      extends: "cp862",
      value: [,,,,,,,,,,,,,, [1488, 1489, 1490, 1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502, 1503], [1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514,,,,,,]]
    }
  },
  o = {
    cp2001: "cp771",
    cp1119: "cp772",
    cp1118: "cp774",
    cp1162: "cp874",
    iso88591: "iso8859-1",
    iso88592: "iso8859-2",
    iso88597: "iso8859-7",
    iso885915: "iso8859-15",
    shiftjis: "epson/katakana",
    katakana: "epson/katakana",
    cp928: "star/cp928"
  },
  i = {
    en: "The quick brown fox jumps over the lazy dog.",
    jp: "イロハニホヘト チリヌルヲ ワカヨタレソ ツネナラム",
    pt: "O próximo vôo à noite sobre o Atlântico, põe freqüentemente o único médico.",
    fr: "Les naïfs ægithales hâtifs pondant à Noël où il gèle sont sûrs d'être déçus en voyant leurs drôles d'œufs abîmés.",
    sv: "Flygande bäckasiner söka strax hwila på mjuka tuvor.",
    dk: "Quizdeltagerne spiste jordbær med fløde",
    el: "ξεσκεπάζω την ψυχοφθόρα βδελυγμία",
    tr: "Pijamalı hasta, yağız şoföre çabucak güvendi.",
    ru: "Съешь же ещё этих мягких французских булок да выпей чаю",
    hu: "Árvíztűrő tükörfúrógép",
    pl: "Pchnąć w tę łódź jeża lub ośm skrzyń fig",
    cz: "Mohu jíst sklo, neublíží mi.",
    ar: "أنا قادر على أكل الزجاج و هذا لا يؤلمني.",
    et: "Ma võin klaasi süüa, see ei tee mulle midagi.",
    lt: "Aš galiu valgyti stiklą ir jis manęs nežeidžia.",
    bg: "Мога да ям стъкло, то не ми вреди.",
    is: "Ég get etið gler án þess að meiða mig.",
    he: "אני יכול לאכול זכוכית וזה לא מזיק לי.",
    fa: ".من می توانم بدونِ احساس درد شيشه بخورم",
    uk: "Я можу їсти скло, і воно мені не зашкодить.",
    vi: "Tôi có thể ăn thủy tinh mà không hại gì.",
    kk: "қазақша",
    lv: "Es varu ēst stiklu, tas man nekaitē.",
    mt: "Nista' niekol il-ħġieġ u ma jagħmilli xejn.",
    th: "ฉันกินกระจกได้ แต่มันไม่ทำให้ฉันเจ็บ"
  };
class n {
  static getEncodings() {
    return Object.keys(a);
  }
  static getEncoding(e) {
    return void 0 !== o[e] && (e = o[e]), void 0 === a[e] && (e = "ascii"), void 0 === a[e].codepoints && (a[e].codepoints = this.getCodepoints(e, !0)), structuredClone(a[e]);
  }
  static getTestStrings(e) {
    return void 0 !== o[e] && (e = o[e]), void 0 !== a[e] && void 0 !== a[e].languages ? a[e].languages.map(e => ({
      language: e,
      string: i[e]
    })) : [];
  }
  static supports(e) {
    return void 0 !== o[e] && (e = o[e]), void 0 !== a[e];
  }
  static encode(e, t) {
    const s = new Uint8Array(e.length),
      a = this.getEncoding(t);
    for (let t = 0; t < e.length; t++) {
      const o = e.codePointAt(t),
        i = a.codepoints.findIndex(e => e === o);
      s[t] = -1 !== i ? i : 63;
    }
    return s;
  }
  static autoEncode(e, t) {
    const s = [];
    let a,
      o = -1;
    for (let i = 0; i < e.length; i++) {
      const n = e.codePointAt(i);
      let r,
        d = 0;
      if (!r && a) {
        const e = this.getEncoding(a).codepoints.findIndex(e => e === n);
        -1 !== e && (r = a, d = e);
      }
      if (!r) for (let e = 0; e < t.length; e++) {
        const s = this.getEncoding(t[e]).codepoints.findIndex(e => e === n);
        if (-1 !== s) {
          r = t[e], d = s;
          break;
        }
      }
      r || (r = a || t[0], d = 63), a != r && (a && (s[o].bytes = new Uint8Array(s[o].bytes)), o++, s[o] = {
        codepage: r,
        bytes: []
      }, a = r), s[o].bytes.push(d);
    }
    return a && (s[o].bytes = new Uint8Array(s[o].bytes)), s;
  }
  static getCodepoints(e, t) {
    let s = new Array(256);
    if (t && (s = void 0 === a[e].extends ? s.fill(65533) : this.getEncoding(a[e].extends).codepoints), 16 === a[e].value.length) {
      for (let t = 0; t < 16; t++) if ("object" == typeof a[e].value[t]) for (let o = 0; o < 16; o++) "number" == typeof a[e].value[t][o] && (s[16 * t + o] = a[e].value[t][o]);
    } else {
      const t = a[e].offset || 0;
      for (let o = 0; o < a[e].value.length; o++) "number" == typeof a[e].value[o] && (s[t + o] = a[e].value[o]);
    }
    return s;
  }
}
var r = ImageData,
  d = e(r);
const p = r;
function c(e, t) {
  let s = 0;
  for (let a = 0; a < t.height; a++) for (let o = 0; o < t.width; o++) {
    const i = Math.floor(o * e.width / t.width);
    let n = 4 * (Math.floor(a * e.height / t.height) * e.width + i);
    t.data[s++] = e.data[n++], t.data[s++] = e.data[n++], t.data[s++] = e.data[n++], t.data[s++] = e.data[n++];
  }
}
function l(e, t) {
  function s(e, t, s, a, o) {
    return Math.round((e - t) * o + (s - e) * a);
  }
  function a(t, a, o, i, n) {
    const r = e.data[4 * (o * e.width + i) + t];
    if (i === n) return r;
    return s(a, i, n, r, e.data[4 * (o * e.width + n) + t]);
  }
  function o(e, t, o, i, n, r, d) {
    const p = a(e, t, r, o, i);
    if (r === d) return p;
    return s(n, r, d, p, a(e, t, d, o, i));
  }
  let i = 0;
  for (let s = 0; s < t.height; s++) for (let a = 0; a < t.width; a++) {
    const n = a * e.width / t.width,
      r = s * e.height / t.height,
      d = Math.floor(n),
      p = Math.floor(r),
      c = Math.min(Math.ceil(n), e.width - 1),
      l = Math.min(Math.ceil(r), e.height - 1);
    t.data[i++] = o(0, n, d, c, r, p, l), t.data[i++] = o(1, n, d, c, r, p, l), t.data[i++] = o(2, n, d, c, r, p, l), t.data[i++] = o(3, n, d, c, r, p, l);
  }
}
var u = e(function (e, t, s, a) {
  let o;
  switch (a = a || "bilinear-interpolation") {
    case "nearest-neighbor":
      o = c;
      break;
    case "bilinear-interpolation":
    case "biliniear-interpolation":
      o = l;
      break;
    default:
      throw new Error(`Unknown algorithm: ${a}`);
  }
  const i = new p(t, s);
  return o(e, i), i;
});
class h {
  initialize() {
    return [{
      type: "initialize",
      payload: [27, 64]
    }, {
      type: "character-mode",
      value: "single byte",
      payload: [28, 46]
    }, {
      type: "font",
      value: "A",
      payload: [27, 77, 0]
    }];
  }
  font(e) {
    const t = e.charCodeAt(0) - 65;
    return [{
      type: "font",
      value: e,
      payload: [27, 77, t]
    }];
  }
  align(e) {
    let t = 0;
    return "center" === e ? t = 1 : "right" === e && (t = 2), [{
      type: "align",
      value: e,
      payload: [27, 97, t]
    }];
  }
  barcode(e, t, s) {
    const a = [],
      o = {
        upca: 0,
        upce: 1,
        ean13: 2,
        ean8: 3,
        code39: 4,
        coda39: 4,
        itf: 5,
        "interleaved-2-of-5": 5,
        "nw-7": 6,
        codabar: 6,
        code93: 72,
        code128: 73,
        "gs1-128": 72,
        "gs1-databar-omni": 75,
        "gs1-databar-truncated": 76,
        "gs1-databar-limited": 77,
        "gs1-databar-expanded": 78,
        "code128-auto": 79
      };
    if ("string" == typeof t && void 0 === o[t]) throw new Error(`Symbology '${t}' not supported by language`);
    if (s.width < 1 || s.width > 3) throw new Error("Width must be between 1 and 3");
    let i = s.width + 1;
    "itf" === t && (i = 2 * s.width), "gs1-128" !== t && "gs1-databar-omni" !== t && "gs1-databar-truncated" !== t && "gs1-databar-limited" !== t && "gs1-databar-expanded" !== t || (i = s.width), a.push({
      type: "barcode",
      property: "height",
      value: s.height,
      payload: [29, 104, s.height]
    }, {
      type: "barcode",
      property: "width",
      value: s.width,
      payload: [29, 119, i]
    }, {
      type: "barcode",
      property: "text",
      value: s.text,
      payload: [29, 72, s.text ? 2 : 0]
    }), "code128" != t || e.startsWith("{") || (e = "{B" + e), "gs1-128" == t && (e = e.replace(/[()*]/g, ""));
    const r = n.encode(e, "ascii"),
      d = "string" == typeof t ? o[t] : t;
    return d > 64 ? a.push({
      type: "barcode",
      value: {
        symbology: t,
        data: e
      },
      payload: [29, 107, d, r.length, ...r]
    }) : a.push({
      type: "barcode",
      value: {
        symbology: t,
        data: e
      },
      payload: [29, 107, d, ...r, 0]
    }), a;
  }
  qrcode(e, t) {
    const s = [];
    if ("number" == typeof t.model) {
      const e = {
        1: 49,
        2: 50
      };
      if (!(t.model in e)) throw new Error("Model must be 1 or 2");
      s.push({
        type: "qrcode",
        property: "model",
        value: t.model,
        payload: [29, 40, 107, 4, 0, 49, 65, e[t.model], 0]
      });
    }
    if ("number" != typeof t.size) throw new Error("Size must be a number");
    if (t.size < 1 || t.size > 8) throw new Error("Size must be between 1 and 8");
    s.push({
      type: "qrcode",
      property: "size",
      value: t.size,
      payload: [29, 40, 107, 3, 0, 49, 67, t.size]
    });
    const a = {
      l: 48,
      m: 49,
      q: 50,
      h: 51
    };
    if (!(t.errorlevel in a)) throw new Error("Error level must be l, m, q or h");
    s.push({
      type: "qrcode",
      property: "errorlevel",
      value: t.errorlevel,
      payload: [29, 40, 107, 3, 0, 49, 69, a[t.errorlevel]]
    });
    const o = n.encode(e, "iso8859-1"),
      i = o.length + 3;
    return s.push({
      type: "qrcode",
      property: "data",
      value: e,
      payload: [29, 40, 107, 255 & i, i >> 8 & 255, 49, 80, 48, ...o]
    }), s.push({
      type: "qrcode",
      command: "print",
      payload: [29, 40, 107, 3, 0, 49, 81, 48]
    }), s;
  }
  pdf417(e, t) {
    const s = [];
    if ("number" != typeof t.columns) throw new Error("Columns must be a number");
    if (0 !== t.columns && (t.columns < 1 || t.columns > 30)) throw new Error("Columns must be 0, or between 1 and 30");
    if (s.push({
      type: "pdf417",
      property: "columns",
      value: t.columns,
      payload: [29, 40, 107, 3, 0, 48, 65, t.columns]
    }), "number" != typeof t.rows) throw new Error("Rows must be a number");
    if (0 !== t.rows && (t.rows < 3 || t.rows > 90)) throw new Error("Rows must be 0, or between 3 and 90");
    if (s.push({
      type: "pdf417",
      property: "rows",
      value: t.rows,
      payload: [29, 40, 107, 3, 0, 48, 66, t.rows]
    }), "number" != typeof t.width) throw new Error("Width must be a number");
    if (t.width < 2 || t.width > 8) throw new Error("Width must be between 2 and 8");
    if (s.push({
      type: "pdf417",
      property: "width",
      value: t.width,
      payload: [29, 40, 107, 3, 0, 48, 67, t.width]
    }), "number" != typeof t.height) throw new Error("Height must be a number");
    if (t.height < 2 || t.height > 8) throw new Error("Height must be between 2 and 8");
    if (s.push({
      type: "pdf417",
      property: "height",
      value: t.height,
      payload: [29, 40, 107, 3, 0, 48, 68, t.height]
    }), "number" != typeof t.errorlevel) throw new Error("Errorlevel must be a number");
    if (t.errorlevel < 0 || t.errorlevel > 8) throw new Error("Errorlevel must be between 0 and 8");
    s.push({
      type: "pdf417",
      property: "errorlevel",
      value: t.errorlevel,
      payload: [29, 40, 107, 4, 0, 48, 69, 48, t.errorlevel + 48]
    }), s.push({
      type: "pdf417",
      property: "truncated",
      value: !!t.truncated,
      payload: [29, 40, 107, 3, 0, 48, 70, t.truncated ? 1 : 0]
    });
    const a = n.encode(e, "ascii"),
      o = a.length + 3;
    return s.push({
      type: "pdf417",
      property: "data",
      value: e,
      payload: [29, 40, 107, 255 & o, o >> 8 & 255, 48, 80, 48, ...a]
    }), s.push({
      type: "pdf417",
      command: "print",
      payload: [29, 40, 107, 3, 0, 48, 81, 48]
    }), s;
  }
  image(e, t, s, a) {
    const o = [],
      i = (a, o) => a < t && o < s ? e.data[4 * (t * o + a)] > 0 ? 0 : 1 : 0,
      n = (e, t) => {
        const s = new Uint8Array(e * t >> 3);
        for (let a = 0; a < t; a++) for (let t = 0; t < e; t += 8) for (let o = 0; o < 8; o++) s[a * (e >> 3) + (t >> 3)] |= i(t + o, a) << 7 - o;
        return s;
      };
    return "column" == a && (o.push({
      type: "line-spacing",
      value: "24 dots",
      payload: [27, 51, 36]
    }), ((e, t) => {
      const s = [];
      for (let a = 0; a < Math.ceil(t / 24); a++) {
        const t = new Uint8Array(3 * e);
        for (let s = 0; s < e; s++) for (let e = 0; e < 3; e++) for (let o = 0; o < 8; o++) t[3 * s + e] |= i(s, 24 * a + o + 8 * e) << 7 - o;
        s.push(t);
      }
      return s;
    })(t, s).forEach(e => {
      o.push({
        type: "image",
        property: "data",
        value: "column",
        width: t,
        height: 24,
        payload: [27, 42, 33, 255 & t, t >> 8 & 255, ...e, 10]
      });
    }), o.push({
      type: "line-spacing",
      value: "default",
      payload: [27, 50]
    })), "raster" == a && o.push({
      type: "image",
      command: "data",
      value: "raster",
      width: t,
      height: s,
      payload: [29, 118, 48, 0, t >> 3 & 255, t >> 3 >> 8 & 255, 255 & s, s >> 8 & 255, ...n(t, s)]
    }), o;
  }
  cut(e) {
    let t = 0;
    return "partial" == e && (t = 1), [{
      type: "cut",
      payload: [29, 86, t]
    }];
  }
  pulse(e, t, s) {
    return void 0 === e && (e = 0), void 0 === t && (t = 100), void 0 === s && (s = 500), [{
      type: "pulse",
      payload: [27, 112, e ? 1 : 0, 255 & (t = Math.min(500, Math.round(t / 2))), 255 & (s = Math.min(500, Math.round(s / 2)))]
    }];
  }
  bold(e) {
    let t = 0;
    return e && (t = 1), [27, 69, t];
  }
  underline(e) {
    let t = 0;
    return e && (t = 1), [27, 45, t];
  }
  italic(e) {
    let t = 0;
    return e && (t = 1), [27, 52, t];
  }
  invert(e) {
    let t = 0;
    return e && (t = 1), [29, 66, t];
  }
  size(e, t) {
    return [29, 33, t - 1 | e - 1 << 4];
  }
  codepage(e) {
    return [27, 116, e];
  }
  flush() {
    return [];
  }
}
class g {
  initialize() {
    return [{
      type: "initialize",
      payload: [27, 64, 24]
    }];
  }
  font(e) {
    let t = 0;
    return "B" === e && (t = 1), "C" === e && (t = 2), [{
      type: "font",
      value: e,
      payload: [27, 30, 70, t]
    }];
  }
  align(e) {
    let t = 0;
    return "center" === e ? t = 1 : "right" === e && (t = 2), [{
      type: "align",
      value: e,
      payload: [27, 29, 97, t]
    }];
  }
  barcode(e, t, s) {
    const a = [],
      o = {
        upce: 0,
        upca: 1,
        ean8: 2,
        ean13: 3,
        code39: 4,
        itf: 5,
        "interleaved-2-of-5": 5,
        code128: 6,
        code93: 7,
        "nw-7": 8,
        codabar: 8,
        "gs1-128": 9,
        "gs1-databar-omni": 10,
        "gs1-databar-truncated": 11,
        "gs1-databar-limited": 12,
        "gs1-databar-expanded": 13
      };
    if ("string" == typeof t && void 0 === o[t]) throw new Error(`Symbology '${t}' not supported by language`);
    if (s.width < 1 || s.width > 3) throw new Error("Width must be between 1 and 3");
    "code128" === t && e.startsWith("{") && (e = e.slice(2));
    const i = n.encode(e, "ascii"),
      r = "string" == typeof t ? o[t] : t;
    return a.push({
      type: "barcode",
      value: {
        symbology: t,
        data: e,
        width: s.width,
        height: s.height,
        text: s.text
      },
      payload: [27, 98, r, s.text ? 2 : 1, s.width, s.height, ...i, 30]
    }), a;
  }
  qrcode(e, t) {
    const s = [],
      a = {
        1: 1,
        2: 2
      };
    if (!(t.model in a)) throw new Error("Model must be 1 or 2");
    if (s.push({
      type: "qrcode",
      property: "model",
      value: t.model,
      payload: [27, 29, 121, 83, 48, a[t.model]]
    }), "number" != typeof t.size) throw new Error("Size must be a number");
    if (t.size < 1 || t.size > 8) throw new Error("Size must be between 1 and 8");
    s.push({
      type: "qrcode",
      property: "size",
      value: t.size,
      payload: [27, 29, 121, 83, 50, t.size]
    });
    const o = {
      l: 0,
      m: 1,
      q: 2,
      h: 3
    };
    if (!(t.errorlevel in o)) throw new Error("Error level must be l, m, q or h");
    s.push({
      type: "qrcode",
      property: "errorlevel",
      value: t.errorlevel,
      payload: [27, 29, 121, 83, 49, o[t.errorlevel]]
    });
    const i = n.encode(e, "iso8859-1"),
      r = i.length;
    return s.push({
      type: "qrcode",
      property: "data",
      value: e,
      payload: [27, 29, 121, 68, 49, 0, 255 & r, r >> 8 & 255, ...i]
    }), s.push({
      type: "qrcode",
      command: "print",
      payload: [27, 29, 121, 80]
    }), s;
  }
  pdf417(e, t) {
    const s = [];
    if ("number" != typeof t.columns) throw new Error("Columns must be a number");
    if (0 !== t.columns && (t.columns < 1 || t.columns > 30)) throw new Error("Columns must be 0, or between 1 and 30");
    if ("number" != typeof t.rows) throw new Error("Rows must be a number");
    if (0 !== t.rows && (t.rows < 3 || t.rows > 90)) throw new Error("Rows must be 0, or between 3 and 90");
    if (s.push({
      type: "pdf417",
      value: `rows: ${t.rows}, columns: ${t.columns}`,
      payload: [27, 29, 120, 83, 48, 1, t.rows, t.columns]
    }), "number" != typeof t.width) throw new Error("Width must be a number");
    if (t.width < 2 || t.width > 8) throw new Error("Width must be between 2 and 8");
    if (s.push({
      type: "pdf417",
      property: "width",
      value: t.width,
      payload: [27, 29, 120, 83, 50, t.width]
    }), "number" != typeof t.height) throw new Error("Height must be a number");
    if (t.height < 2 || t.height > 8) throw new Error("Height must be between 2 and 8");
    if (s.push({
      type: "pdf417",
      property: "height",
      value: t.height,
      payload: [27, 29, 120, 83, 51, t.height]
    }), "number" != typeof t.errorlevel) throw new Error("Errorlevel must be a number");
    if (t.errorlevel < 0 || t.errorlevel > 8) throw new Error("Errorlevel must be between 0 and 8");
    s.push({
      type: "pdf417",
      property: "errorlevel",
      value: t.errorlevel,
      payload: [27, 29, 120, 83, 49, t.errorlevel]
    });
    const a = n.encode(e, "ascii"),
      o = a.length;
    return s.push({
      type: "pdf417",
      property: "data",
      value: e,
      payload: [27, 29, 120, 68, 255 & o, o >> 8 & 255, ...a]
    }), s.push({
      type: "pdf417",
      command: "print",
      payload: [27, 29, 120, 80]
    }), s;
  }
  image(e, t, s, a) {
    const o = [],
      i = (s, a) => void 0 === e.data[4 * (t * a + s)] || e.data[4 * (t * a + s)] > 0 ? 0 : 1;
    o.push({
      type: "line-spacing",
      value: "24 dots",
      payload: [27, 48]
    });
    for (let e = 0; e < s / 24; e++) {
      const s = 24 * e,
        a = new Uint8Array(3 * t);
      for (let e = 0; e < t; e++) {
        const t = 3 * e;
        a[t] = i(e, s + 0) << 7 | i(e, s + 1) << 6 | i(e, s + 2) << 5 | i(e, s + 3) << 4 | i(e, s + 4) << 3 | i(e, s + 5) << 2 | i(e, s + 6) << 1 | i(e, s + 7), a[t + 1] = i(e, s + 8) << 7 | i(e, s + 9) << 6 | i(e, s + 10) << 5 | i(e, s + 11) << 4 | i(e, s + 12) << 3 | i(e, s + 13) << 2 | i(e, s + 14) << 1 | i(e, s + 15), a[t + 2] = i(e, s + 16) << 7 | i(e, s + 17) << 6 | i(e, s + 18) << 5 | i(e, s + 19) << 4 | i(e, s + 20) << 3 | i(e, s + 21) << 2 | i(e, s + 22) << 1 | i(e, s + 23);
      }
      o.push({
        type: "image",
        property: "data",
        value: "column",
        width: t,
        height: 24,
        payload: [27, 88, 255 & t, t >> 8 & 255, ...a, 10, 13]
      });
    }
    return o.push({
      type: "line-spacing",
      value: "default",
      payload: [27, 122, 1]
    }), o;
  }
  cut(e) {
    let t = 0;
    return "partial" == e && (t = 1), [{
      type: "cut",
      payload: [27, 100, t]
    }];
  }
  pulse(e, t, s) {
    return void 0 === e && (e = 0), void 0 === t && (t = 200), void 0 === s && (s = 200), [{
      type: "pulse",
      payload: [27, 7, 255 & (t = Math.min(127, Math.round(t / 10))), 255 & (s = Math.min(127, Math.round(s / 10))), e ? 26 : 7]
    }];
  }
  bold(e) {
    let t = 70;
    return e && (t = 69), [27, t];
  }
  underline(e) {
    let t = 0;
    return e && (t = 1), [27, 45, t];
  }
  italic(e) {
    return [];
  }
  invert(e) {
    let t = 53;
    return e && (t = 52), [27, t];
  }
  size(e, t) {
    return [27, 105, t - 1, e - 1];
  }
  codepage(e) {
    return [27, 29, 116, e];
  }
  flush() {
    return [{
      type: "print-mode",
      value: "page",
      payload: [27, 29, 80, 48]
    }, {
      type: "print-mode",
      value: "line",
      payload: [27, 29, 80, 49]
    }];
  }
}
class m {
  #e = {
    bold: !1,
    italic: !1,
    underline: !1,
    invert: !1,
    width: 1,
    height: 1
  };
  #t;
  #s;
  constructor(e) {
    this.#t = structuredClone(this.#e), this.#s = e.callback || (() => {});
  }
  store() {
    const e = [],
      t = new Map();
    for (const e in this.#t) this.#t[e] !== this.#e[e] && ("width" === e || "height" === e ? t.set("size", {
      width: this.#e.width,
      height: this.#e.height
    }) : t.set(e, this.#e[e]));
    for (const s of t) e.push({
      type: "style",
      property: s[0],
      value: s[1]
    });
    return e;
  }
  restore() {
    const e = [],
      t = new Map();
    for (const e in this.#t) this.#t[e] !== this.#e[e] && ("width" === e || "height" === e ? t.set("size", {
      width: this.#t.width,
      height: this.#t.height
    }) : t.set(e, this.#t[e]));
    for (const s of t) e.push({
      type: "style",
      property: s[0],
      value: s[1]
    });
    return e;
  }
  set bold(e) {
    e !== this.#t.bold && (this.#t.bold = e, this.#s({
      type: "style",
      property: "bold",
      value: e
    }));
  }
  get bold() {
    return this.#t.bold;
  }
  set italic(e) {
    e !== this.#t.italic && (this.#t.italic = e, this.#s({
      type: "style",
      property: "italic",
      value: e
    }));
  }
  get italic() {
    return this.#t.italic;
  }
  set underline(e) {
    e !== this.#t.underline && (this.#t.underline = e, this.#s({
      type: "style",
      property: "underline",
      value: e
    }));
  }
  get underline() {
    return this.#t.underline;
  }
  set invert(e) {
    e !== this.#t.invert && (this.#t.invert = e, this.#s({
      type: "style",
      property: "invert",
      value: e
    }));
  }
  get invert() {
    return this.#t.invert;
  }
  set width(e) {
    e !== this.#t.width && (this.#t.width = e, this.#s({
      type: "style",
      property: "size",
      value: {
        width: this.#t.width,
        height: this.#t.height
      }
    }));
  }
  get width() {
    return this.#t.width;
  }
  set height(e) {
    e !== this.#t.height && (this.#t.height = e, this.#s({
      type: "style",
      property: "size",
      value: {
        width: this.#t.width,
        height: this.#t.height
      }
    }));
  }
  get height() {
    return this.#t.height;
  }
}
class f {
  static wrap(e, t) {
    const s = [];
    let a = [],
      o = t.indent || 0;
    const i = t.width || 1,
      n = t.columns || 42,
      r = String(e).split(/\r\n|\n/g);
    for (const e of r) {
      const t = e.match(/[^\s-]+?-\b|\S+|\s+|\r\n?|\n/g) || ["~~empty~~"];
      for (const e of t) if ("~~empty~~" !== e) {
        if (o + e.length * i > n) {
          if (e.length * i > n) {
            const t = n - o,
              r = e.split("");
            let d;
            const p = [];
            for (t > 8 * i && (d = r.splice(0, Math.floor(t / i)).join(""), a.push(d), s.push(a), a = [], o = 0); (d = r.splice(0, Math.floor(n / i))).length;) p.push(d.join(""));
            for (const e of p) o + e.length * i > n && (s.push(a), a = [], o = 0), a.push(e), o += e.length * i;
            continue;
          }
          s.push(a), a = [], o = 0;
        }
        e.match(/\s+/) && 0 == o || (a.push(e), o += e.length * i);
      } else s.push(a), a = [], o = 0;
      a.length > 0 && (s.push(a), a = [], o = 0);
    }
    for (let e = 0; e < s.length; e++) s[e] = s[e].join(""), e < s.length - 1 && (s[e] = s[e].trimEnd());
    return s;
  }
}
class w {
  #a;
  #o;
  #i;
  #s;
  #n = 0;
  #r;
  #d = [];
  constructor(e) {
    this.#a = e.embedded || !1, this.#o = e.columns || 42, this.#i = e.align || "left", this.#s = e.callback || (() => {}), this.style = new m({
      callback: e => {
        this.add(e, 0);
      }
    }), this.#r = this.style.store();
  }
  text(e, t) {
    const s = f.wrap(e, {
      columns: this.#o,
      width: this.style.width,
      indent: this.#n
    });
    for (let e = 0; e < s.length; e++) s[e].length ? (this.add({
      type: "text",
      value: s[e],
      codepage: t
    }, s[e].length * this.style.width), e < s.length - 1 && this.flush()) : this.flush({
      forceNewline: !0
    });
  }
  space(e) {
    this.add({
      type: "space",
      size: e
    }, e);
  }
  raw(e, t) {
    this.add({
      type: "raw",
      payload: e
    }, t || 0);
  }
  add(e, t) {
    if (e instanceof Array) {
      for (const t of e) this.add(t);
      this.#n += t || 0;
    } else (t = t || 0) + this.#n > this.#o && this.flush(), this.#n += t, this.#d = this.#d.concat(e);
  }
  end() {
    this.#n = this.#o;
  }
  fetch(e) {
    if (0 === this.#n && !e.forceNewline && !e.forceFlush) return [];
    const t = {
      current: this.#i,
      next: null
    };
    for (let e = 0; e < this.#d.length - 1; e++) "align" !== this.#d[e].type || this.#d[e].payload || (t.current = this.#d[e].value);
    if (this.#d.length) {
      const e = this.#d[this.#d.length - 1];
      "align" !== e.type || e.payload || (t.next = e.value);
    }
    this.#i = t.current;
    const s = this.#d.filter(e => "align" !== e.type || e.payload);
    let a = [];
    const o = this.style.restore(),
      i = this.style.store();
    if (0 !== this.#n || !e.ignoreAlignment && this.#a) {
      if ("right" === this.#i) {
        let e;
        for (let t = s.length - 1; t >= 0; t--) if ("text" === s[t].type || "space" === s[t].type) {
          e = t;
          break;
        }
        "number" == typeof e && ("space" === s[e].type && s[e].size > this.style.width && (s[e].size -= this.style.width, this.#n -= this.style.width), "text" === s[e].type && s[e].value.endsWith(" ") && (s[e].value = s[e].value.slice(0, -1), this.#n -= this.style.width)), a = this.#p([{
          type: "space",
          size: this.#o - this.#n
        }, ...this.#r, ...s, ...i]);
      }
      if ("center" === this.#i) {
        const e = this.#o - this.#n >> 1;
        a = this.#p([{
          type: "space",
          size: e
        }, ...this.#r, ...s, ...i, {
          type: "space",
          size: this.#a ? this.#o - this.#n - e : 0
        }]);
      }
      "left" === this.#i && (a = this.#p([...this.#r, ...s, ...i, {
        type: "space",
        size: this.#a ? this.#o - this.#n : 0
      }]));
    } else a = this.#p([...this.#r, ...s, ...i]);
    return this.#r = o, this.#d = [], this.#n = 0, 0 === a.length && e.forceNewline && a.push({
      type: "empty"
    }), t.next && (this.#i = t.next), a;
  }
  flush(e) {
    e = Object.assign({
      forceNewline: !1,
      forceFlush: !1,
      ignoreAlignment: !1
    }, e || {});
    const t = this.fetch(e);
    t.length && this.#s(t);
  }
  #p(e) {
    const t = [];
    let s = -1;
    for (let a of e) {
      if ("space" === a.type) {
        if (0 === a.size) continue;
        a = {
          type: "text",
          value: " ".repeat(a.size),
          codepage: null
        };
      }
      if ("text" === a.type) {
        if (s >= 0 && "text" === t[s].type && (t[s].codepage === a.codepage || null === t[s].codepage || null === a.codepage)) {
          t[s].value += a.value, t[s].codepage = t[s].codepage || a.codepage;
          continue;
        }
        t.push(a), s++;
      } else if ("style" === a.type && "size" === a.property) {
        if (s >= 0 && "style" === t[s].type && "size" === t[s].property) {
          t[s].value = a.value;
          continue;
        }
        t.push(a), s++;
      } else t.push(a), s++;
    }
    return t;
  }
  get cursor() {
    return this.#n;
  }
  set align(e) {
    this.add({
      type: "align",
      value: e
    }, 0);
  }
  get align() {
    let e = this.#i;
    for (let t = 0; t < this.#d.length; t++) "align" === this.#d[t].type && (e = this.#d[t].value);
    return e;
  }
  set columns(e) {
    this.#o = e;
  }
  get columns() {
    return this.#o;
  }
}
const b = {
  "esc-pos": {
    "bixolon/legacy": ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865",,,,,,,,,,,,,, "cp858"],
    bixolon: ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865",,,,,,,,,,, "windows1252", "cp866", "cp852", "cp858",, "cp862", "cp864", "thai42", "windows1253", "windows1254", "windows1257",, "windows1251", "cp737", "cp775", "thai14", "bixolon/hebrew", "windows1255", "thai11", "thai18", "cp885", "cp857", "iso8859-7", "thai16", "windows1256", "windows1258", "khmer",,,, "bixolon/cp866", "windows1250",, "tcvn3", "tcvn3capitals", "viscii"],
    citizen: ["cp437", "epson/katakana", "cp858", "cp860", "cp863", "cp865", "cp852", "cp866", "cp857",,,,,,,, "windows1252",,,,, "thai11",,,,, "thai13",,,, "tcvn3", "tcvn3capitals", "windows1258",,,,,,,, "cp864"],
    "epson/legacy": ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865",,,,,,,,,,, "windows1252", "cp866", "cp852", "cp858"],
    epson: ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865",,,,,, "cp851", "cp853", "cp857", "cp737", "iso8859-7", "windows1252", "cp866", "cp852", "cp858", "thai42", "thai11",,,,, "thai13",,,, "tcvn3", "tcvn3capitals", "cp720", "cp775", "cp855", "cp861", "cp862", "cp864", "cp869", "epson/iso8859-2", "iso8859-15", "cp1098", "cp774", "cp772", "cp1125", "windows1250", "windows1251", "windows1253", "windows1254", "windows1255", "windows1256", "windows1257", "windows1258", "rk1048"],
    fujitsu: ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865",,, "cp857",,,,,,,, "windows1252", "cp866", "cp852", "cp858",,,,,,, "thai13",,,,,,,,,,,,,, "cp864"],
    hp: ["cp437", "cp850", "cp852", "cp860", "cp863", "cp865", "cp858", "cp866", "windows1252", "cp862", "cp737", "cp874", "cp857", "windows1251", "windows1255", "rk1048"],
    metapace: ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865",,,,,,,,,,,,,, "cp858"],
    mpt: ["cp437",, "cp850", "cp860", "cp863", "cp865", "windows1251", "cp866", "cp3021", "cp3012"],
    "pos-5890": ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865", "iso8859-1",, "cp862",,,,,,,, "windows1252", "cp866", "cp852", "cp858",,,, "windows1251", "cp737", "windows1257",, "windows1258", "cp864",,,, "windows1255",,,,,,,,,,,,,,,,,,,,,,,, "cp861",,,, "cp855", "cp857",,,, "cp851", "cp869",, "cp772", "cp774",,, "windows1250",, "cp3840",, "cp3843", "cp3844", "cp3845", "cp3846", "cp3847", "cp3848",, "cp771", "cp3001", "cp3002", "cp3011", "cp3012",, "cp3041", "windows1253", "windows1254", "windows1256", "cp720",, "cp775"],
    "pos-8360": ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865", "iso8859-1", "windows1253", "cp862",,,,,,,, "windows1252", "cp866", "cp852", "cp858",, "latvian",, "windows1251", "cp737", "windows1257",, "windows1258", "cp864",,, "pos8360/hebrew", "windows1255",,,,,,,,,,,,,,,,,,,,,,,, "cp861",,,, "cp855", "cp857",,,, "cp851", "cp869",, "cp772", "cp774",,, "windows1250",, "cp3840",, "cp3843", "cp3844", "cp3845", "cp3846", "cp3847", "cp3848",, "cp771", "cp3001", "cp3002", "cp3011", "cp3012",,,, "windows1254", "windows1256", "cp720",, "cp775"],
    star: ["cp437", "star/katakana", "cp850", "cp860", "cp863", "cp865",,,,,,,,,,, "windows1252", "cp866", "cp852", "cp858", "thai42", "thai11", "thai13", "thai14", "thai16",, "thai18"],
    xprinter: ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865", "iso8859-1", "windows1253", "xprinter/hebrew", "cp3012",, "windows1255",,,,, "windows1252", "cp866", "cp852", "cp858",, "latvian", "cp864", "windows1251", "cp737", "windows1257",,,,,,,, "windows1256"],
    youku: ["cp437", "epson/katakana", "cp850", "cp860", "cp863", "cp865", "windows1251", "cp866", "cp3021", "cp3012",,,,,, "cp862", "windows1252",, "cp852", "cp858",,, "cp864", "iso8859-1", "cp737", "windows1257",,, "cp855", "cp857", "windows1250", "cp775", "windows1254", "windows1255", "windows1256", "windows1258",,, "iso8859-1",,,,,, "iso8859-15",,, "cp874"]
  },
  "star-prnt": {
    star: ["star/standard", "cp437", "star/katakana",, "cp858", "cp852", "cp860", "cp861", "cp863", "cp865", "cp866", "cp855", "cp857", "cp862", "cp864", "cp737", "cp851", "cp869", "star/cp928", "cp772", "cp774", "star/cp874",,,,,,,,,,, "windows1252", "windows1250", "windows1251",,,,,,,,,,,,,,,,,,,,,,,,,,,,,, "cp3840", "cp3841", "cp3843", "cp3844", "cp3845", "cp3846", "cp3847", "cp3848", "cp1001", "cp771", "cp3001", "cp3002", "cp3011", "cp3012", "cp3021", "cp3041"]
  }
};
b["star-line"] = b["star-prnt"], b["esc-pos"].zijang = b["esc-pos"]["pos-5890"];
const y = {
  "bixolon-srp350": {
    vendor: "Bixolon",
    model: "SRP-350",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "bixolon/legacy",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !1,
        models: []
      },
      pdf417: {
        supported: !1
      },
      cutter: {
        feed: 4
      }
    }
  },
  "bixolon-srp350iii": {
    vendor: "Bixolon",
    model: "SRP-350III",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "bixolon",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        },
        C: {
          size: "9x24",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "citizen-ct-s310ii": {
    vendor: "Citizen",
    model: "CT-S310II",
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "citizen",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x24",
          columns: 64
        },
        C: {
          size: "8x16",
          columns: 72
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 3
      }
    }
  },
  "epson-tm-p20ii": {
    vendor: "Epson",
    model: "TM-P20II",
    media: {
      dpi: 203,
      width: 58
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson",
      fonts: {
        A: {
          size: "12x24",
          columns: 32
        },
        B: {
          size: "9x24",
          columns: 42
        },
        C: {
          size: "9x17",
          columns: 42
        },
        D: {
          size: "10x24",
          columns: 38
        },
        E: {
          size: "8x16",
          columns: 48
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded", "code128-auto"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      images: {
        mode: "raster"
      },
      cutter: {
        feed: 3
      }
    }
  },
  "epson-tm-t20iii": {
    vendor: "Epson",
    model: "TM-T20III",
    interfaces: {
      usb: {
        productName: "TM-T20III"
      }
    },
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x17",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "epson-tm-t70": {
    vendor: "Epson",
    model: "TM-T70",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson/legacy",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      images: {
        mode: "raster"
      },
      cutter: {
        feed: 4
      }
    }
  },
  "epson-tm-t70ii": {
    vendor: "Epson",
    model: "TM-T70II",
    interface: {
      usb: {
        productName: "TM-T70II"
      }
    },
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      images: {
        mode: "raster"
      },
      cutter: {
        feed: 4
      }
    }
  },
  "epson-tm-t88ii": {
    vendor: "Epson",
    model: "TM-T88II",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson/legacy",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "epson-tm-t88iii": {
    vendor: "Epson",
    model: "TM-T88III",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson/legacy",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "epson-tm-t88iv": {
    vendor: "Epson",
    model: "TM-T88IV",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson/legacy",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "epson-tm-t88v": {
    vendor: "Epson",
    model: "TM-T88V",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "epson-tm-t88vi": {
    vendor: "Epson",
    model: "TM-T88VI",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "epson-tm-t88vii": {
    vendor: "Epson",
    model: "TM-T88VII",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "epson",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded", "code128-auto"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "fujitsu-fp1000": {
    vendor: "Fujitsu",
    model: "FP-1000",
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "fujitsu",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x24",
          columns: 56
        },
        C: {
          size: "8x16",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !1
      },
      cutter: {
        feed: 4
      }
    }
  },
  "hp-a779": {
    vendor: "HP",
    model: "A779",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "hp",
      newline: "\n",
      fonts: {
        A: {
          size: "12x24",
          columns: 44
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !1,
        fallback: {
          type: "barcode",
          symbology: 75
        }
      },
      cutter: {
        feed: 4
      }
    }
  },
  "metapace-t1": {
    vendor: "Metapace",
    model: "T-1",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "metapace",
      fonts: {
        A: {
          size: "12x24",
          columns: 42
        },
        B: {
          size: "9x17",
          columns: 56
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !1,
        models: []
      },
      pdf417: {
        supported: !1
      },
      cutter: {
        feed: 4
      }
    }
  },
  "mpt-ii": {
    vendor: "",
    model: "MPT-II",
    media: {
      dpi: 180,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "mpt",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x17",
          columns: 64
        },
        C: {
          size: "0x0",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: []
      },
      pdf417: {
        supported: !1
      }
    }
  },
  "pos-5890": {
    vendor: "",
    model: "POS-5890",
    media: {
      dpi: 203,
      width: 58
    },
    capabilities: {
      language: "esc-pos",
      codepages: "pos-5890",
      fonts: {
        A: {
          size: "12x24",
          columns: 32
        },
        B: {
          size: "9x17",
          columns: 42
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["2"]
      },
      pdf417: {
        supported: !0
      },
      images: {
        mode: "raster"
      },
      cutter: {
        feed: 1
      }
    }
  },
  "pos-8360": {
    vendor: "",
    model: "POS-8360",
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "pos-8360",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x17",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["2"]
      },
      pdf417: {
        supported: !0
      },
      images: {
        mode: "raster"
      },
      cutter: {
        feed: 4
      }
    }
  },
  "star-mc-print2": {
    vendor: "Star",
    model: "mC-Print2",
    interfaces: {
      usb: {
        productName: "mC-Print2"
      }
    },
    media: {
      dpi: 203,
      width: 58
    },
    capabilities: {
      language: "star-prnt",
      codepages: "star",
      fonts: {
        A: {
          size: "12x24",
          columns: 32
        },
        B: {
          size: "9x24",
          columns: 42
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "itf", "codabar", "code93", "code128", "gs1-128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 3
      }
    }
  },
  "star-mpop": {
    vendor: "Star",
    model: "mPOP",
    interfaces: {
      usb: {
        productName: "mPOP"
      }
    },
    media: {
      dpi: 203,
      width: 58
    },
    capabilities: {
      language: "star-prnt",
      codepages: "star",
      fonts: {
        A: {
          size: "12x24",
          columns: 32
        },
        B: {
          size: "9x24",
          columns: 42
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "itf", "codabar", "code93", "code128", "gs1-128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 3
      }
    }
  },
  "star-sm-l200": {
    vendor: "Star",
    model: "SM-L200",
    media: {
      dpi: 203,
      width: 58
    },
    capabilities: {
      language: "star-prnt",
      codepages: "star",
      fonts: {
        A: {
          size: "12x24",
          columns: 32
        },
        B: {
          size: "9x24",
          columns: 42
        },
        C: {
          size: "9x17",
          columns: 42
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["2"]
      },
      pdf417: {
        supported: !0
      }
    }
  },
  "star-tsp100iii": {
    vendor: "Star",
    model: "TSP100III",
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "star-prnt",
      codepages: "star",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x24",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 3
      }
    }
  },
  "star-tsp100iv": {
    vendor: "Star",
    model: "TSP100IV",
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "star-prnt",
      codepages: "star",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x24",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 3
      }
    }
  },
  "star-tsp650": {
    vendor: "Star",
    model: "TSP650",
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "star-line",
      codepages: "star",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x24",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !1,
        models: []
      },
      pdf417: {
        supported: !1
      },
      cutter: {
        feed: 3
      }
    }
  },
  "star-tsp650ii": {
    vendor: "Star",
    model: "TSP650II",
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "star-line",
      codepages: "star",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x24",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
      },
      qrcode: {
        supported: !0,
        models: ["1", "2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 3
      }
    }
  },
  "xprinter-xp-n160ii": {
    vendor: "Xprinter",
    model: "XP-N160II",
    interfaces: {
      usb: {
        productName: "Printer-80\0"
      }
    },
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "xprinter",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x17",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-128"]
      },
      qrcode: {
        supported: !0,
        models: ["2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "xprinter-xp-t80q": {
    vendor: "Xprinter",
    model: "XP-T80Q",
    media: {
      dpi: 203,
      width: 80
    },
    capabilities: {
      language: "esc-pos",
      codepages: "xprinter",
      fonts: {
        A: {
          size: "12x24",
          columns: 48
        },
        B: {
          size: "9x17",
          columns: 64
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-128"]
      },
      qrcode: {
        supported: !0,
        models: ["2"]
      },
      pdf417: {
        supported: !0
      },
      cutter: {
        feed: 4
      }
    }
  },
  "youku-58t": {
    vendor: "Youku",
    model: "58T",
    media: {
      dpi: 203,
      width: 58
    },
    capabilities: {
      language: "esc-pos",
      codepages: "youku",
      fonts: {
        A: {
          size: "12x24",
          columns: 32
        },
        B: {
          size: "9x24",
          columns: 42
        }
      },
      barcodes: {
        supported: !0,
        symbologies: ["upca", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128"]
      },
      qrcode: {
        supported: !0,
        models: ["2"]
      },
      pdf417: {
        supported: !1
      }
    }
  }
};
class v {
  #c = {};
  #l = [];
  #u;
  #h;
  #g = {
    fonts: {
      A: {
        size: "12x24",
        columns: 42
      },
      B: {
        size: "9x24",
        columns: 56
      }
    },
    barcodes: {
      supported: !0,
      symbologies: ["upca", "upce", "ean13", "ean8", "code39", "itf", "codabar", "code93", "code128", "gs1-databar-omni", "gs1-databar-truncated", "gs1-databar-limited", "gs1-databar-expanded"]
    },
    qrcode: {
      supported: !0,
      models: ["1", "2"]
    },
    pdf417: {
      supported: !0
    }
  };
  #m = {};
  #f = [];
  #w = "cp437";
  #b = {
    codepage: 0,
    font: "A"
  };
  constructor(e) {
    const t = {
      columns: 42,
      language: "esc-pos",
      imageMode: "column",
      feedBeforeCut: 0,
      newline: "\n\r",
      codepageMapping: "epson",
      codepageCandidates: null,
      errors: "relaxed"
    };
    if ("string" == typeof (e = e || {}).language && (t.columns = "esc-pos" === e.language ? 42 : 48, t.codepageMapping = "esc-pos" === e.language ? "epson" : "star"), "string" == typeof e.printerModel) {
      if (void 0 === y[e.printerModel]) throw new Error("Unknown printer model");
      this.#g = y[e.printerModel].capabilities, t.columns = this.#g.fonts.A.columns, t.language = this.#g.language, t.codepageMapping = this.#g.codepages, t.newline = this.#g?.newline || t.newline, t.feedBeforeCut = this.#g?.cutter?.feed || t.feedBeforeCut, t.imageMode = this.#g?.images?.mode || t.imageMode;
    }
    if (e && (this.#c = Object.assign(t, {
      debug: !1,
      embedded: !1,
      createCanvas: null
    }, e)), this.#c.width && (this.#c.columns = this.#c.width), "esc-pos" === this.#c.language) this.#u = new h();else {
      if ("star-prnt" !== this.#c.language && "star-line" !== this.#c.language) throw new Error("The specified language is not supported");
      this.#u = new g();
    }
    if (void 0 === this.#c.autoFlush && (this.#c.autoFlush = !this.#c.embedded && "star-prnt" == this.#c.language), ![32, 35, 42, 44, 48].includes(this.#c.columns) && !this.#c.embedded) throw new Error("The width of the paper must me either 32, 35, 42, 44 or 48 columns");
    if ("string" == typeof this.#c.codepageMapping) {
      if (void 0 === b[this.#c.language][this.#c.codepageMapping]) throw new Error("Unknown codepage mapping");
      this.#m = Object.fromEntries(b[this.#c.language][this.#c.codepageMapping].map((e, t) => [e, t]).filter(e => e));
    } else this.#m = this.#c.codepageMapping;
    this.#c.codepageCandidates ? this.#f = this.#c.codepageCandidates : this.#f = Object.keys(this.#m), this.#h = new w({
      embedded: this.#c.embedded,
      columns: this.#c.columns,
      align: "left",
      size: 1,
      callback: e => this.#l.push(e)
    }), this.#y();
  }
  #y() {
    this.#l = [], this.#w = "esc-pos" == this.#c.language ? "cp437" : "star/standard", this.#b.codepage = 0, this.#b.font = "A";
  }
  initialize() {
    if (this.#c.embedded) throw new Error("Initialize is not supported in table cells or boxes");
    return this.#h.add(this.#u.initialize()), this;
  }
  codepage(e) {
    if ("auto" === e) return this.#w = e, this;
    if (!n.supports(e)) throw new Error("Unknown codepage");
    if (void 0 === this.#m[e]) throw new Error("Codepage not supported by printer");
    return this.#w = e, this;
  }
  text(e) {
    return this.#h.text(e, this.#w), this;
  }
  newline(e) {
    e = parseInt(e, 10) || 1;
    for (let t = 0; t < e; t++) this.#h.flush({
      forceNewline: !0
    });
    return this;
  }
  line(e) {
    return this.text(e), this.newline(), this;
  }
  underline(e) {
    return this.#h.style.underline = void 0 === e ? !this.#h.style.underline : e, this;
  }
  italic(e) {
    return this.#h.style.italic = void 0 === e ? !this.#h.style.italic : e, this;
  }
  bold(e) {
    return this.#h.style.bold = void 0 === e ? !this.#h.style.bold : e, this;
  }
  invert(e) {
    return this.#h.style.invert = void 0 === e ? !this.#h.style.invert : e, this;
  }
  width(e) {
    if (void 0 === e && (e = 1), "number" != typeof e) throw new Error("Width must be a number");
    if (e < 1 || e > 8) throw new Error("Width must be between 1 and 8");
    return this.#h.style.width = e, this;
  }
  height(e) {
    if (void 0 === e && (e = 1), "number" != typeof e) throw new Error("Height must be a number");
    if (e < 1 || e > 8) throw new Error("Height must be between 1 and 8");
    return this.#h.style.height = e, this;
  }
  size(e, t) {
    return "string" == typeof e ? this.font("small" === e ? "B" : "A") : (void 0 === t && (t = e), this.width(e), this.height(t), this);
  }
  font(e) {
    if (this.#c.embedded) throw new Error("Changing fonts is not supported in table cells or boxes");
    if (this.#h.cursor > 0) throw new Error("Changing fonts is not supported in the middle of a line");
    const t = e.match(/^[0-9]+x[0-9]+$/);
    return t && (e = Object.entries(this.#g.fonts).find(e => e[1].size == t[0])[0]), e = e.toUpperCase(), void 0 === this.#g.fonts[e] ? this.#v("This font is not supported by this printer", "relaxed") : (this.#h.add(this.#u.font(e)), this.#b.font = e, this.#h.columns = "A" === e ? this.#c.columns : this.#c.columns / this.#g.fonts.A.columns * this.#g.fonts[e].columns, this);
  }
  align(e) {
    if (!["left", "center", "right"].includes(e)) throw new Error("Unknown alignment");
    return this.#h.align = e, this;
  }
  table(e, t) {
    this.#h.flush();
    for (let s = 0; s < t.length; s++) {
      const a = [];
      let o = 0;
      for (let i = 0; i < e.length; i++) {
        const n = new v(Object.assign({}, this.#c, {
          width: e[i].width,
          embedded: !0
        }));
        n.codepage(this.#w), n.align(e[i].align), "string" == typeof t[s][i] && n.text(t[s][i]), "function" == typeof t[s][i] && t[s][i](n);
        const r = n.commands();
        o = Math.max(o, r.length), a[i] = r;
      }
      for (let t = 0; t < e.length; t++) if (!(a[t].length >= o)) for (let s = a[t].length; s < o; s++) {
        let s = "top";
        void 0 !== e[t].verticalAlign && (s = e[t].verticalAlign);
        const o = {
          commands: [{
            type: "space",
            size: e[t].width
          }],
          height: 1
        };
        "bottom" == s ? a[t].unshift(o) : a[t].push(o);
      }
      for (let t = 0; t < o; t++) {
        for (let s = 0; s < e.length; s++) void 0 !== e[s].marginLeft && this.#h.space(e[s].marginLeft), this.#h.add(a[s][t].commands, e[s].width), void 0 !== e[s].marginRight && this.#h.space(e[s].marginRight);
        this.#h.flush();
      }
    }
    return this;
  }
  rule(e) {
    return e = Object.assign({
      style: "single",
      width: this.#c.columns || 10
    }, e || {}), this.#h.flush(), this.#h.text(("double" === e.style ? "═" : "─").repeat(e.width), "cp437"), this.#h.flush({
      forceNewline: !0
    }), this;
  }
  box(e, t) {
    if ((e = Object.assign({
      style: "single",
      width: this.#c.columns,
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0
    }, e || {})).width + e.marginLeft + e.marginRight > this.#c.columns) throw new Error("Box is too wide");
    let s;
    "single" == e.style ? s = ["┌", "┐", "└", "┘", "─", "│"] : "double" == e.style && (s = ["╔", "╗", "╚", "╝", "═", "║"]);
    const a = new v(Object.assign({}, this.#c, {
      width: e.width - ("none" == e.style ? 0 : 2) - e.paddingLeft - e.paddingRight,
      embedded: !0
    }));
    a.codepage(this.#w), a.align(e.align), "function" == typeof t && t(a), "string" == typeof t && a.text(t);
    const o = a.commands();
    this.#h.flush(), "none" != e.style && (this.#h.space(e.marginLeft), this.#h.text(s[0], "cp437"), this.#h.text(s[4].repeat(e.width - 2), "cp437"), this.#h.text(s[1], "cp437"), this.#h.space(e.marginRight), this.#h.flush());
    for (let t = 0; t < o.length; t++) this.#h.space(e.marginLeft), "none" != e.style && (this.#h.style.height = o[t].height, this.#h.text(s[5], "cp437"), this.#h.style.height = 1), this.#h.space(e.paddingLeft), this.#h.add(o[t].commands, e.width - ("none" == e.style ? 0 : 2) - e.paddingLeft - e.paddingRight), this.#h.space(e.paddingRight), "none" != e.style && (this.#h.style.height = o[t].height, this.#h.text(s[5], "cp437"), this.#h.style.height = 1), this.#h.space(e.marginRight), this.#h.flush();
    return "none" != e.style && (this.#h.space(e.marginLeft), this.#h.text(s[2], "cp437"), this.#h.text(s[4].repeat(e.width - 2), "cp437"), this.#h.text(s[3], "cp437"), this.#h.space(e.marginRight), this.#h.flush()), this;
  }
  barcode(e, t, s) {
    let a = {
      height: 60,
      width: 2,
      text: !1
    };
    if ("object" == typeof s && (a = Object.assign(a, s)), "number" == typeof s && (a.height = s), this.#c.embedded) throw new Error("Barcodes are not supported in table cells or boxes");
    return !1 === this.#g.barcodes.supported ? this.#v("Barcodes are not supported by this printer", "relaxed") : "string" != typeof t || this.#g.barcodes.symbologies.includes(t) ? (this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), "left" !== this.#h.align && this.#h.add(this.#u.align(this.#h.align)), this.#h.add(this.#u.barcode(e, t, a)), "left" !== this.#h.align && this.#h.add(this.#u.align("left")), this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), this) : this.#v(`Symbology '${t}' not supported by this printer`, "relaxed");
  }
  qrcode(e, t, s, a) {
    let o = {
      model: 2,
      size: 6,
      errorlevel: "m"
    };
    if ("object" == typeof t && (o = Object.assign(o, t)), "number" == typeof t && (o.model = t), "number" == typeof s && (o.size = s), "string" == typeof a && (o.errorlevel = a), this.#c.embedded) throw new Error("QR codes are not supported in table cells or boxes");
    return !1 === this.#g.qrcode.supported ? this.#v("QR codes are not supported by this printer", "relaxed") : o.model && !this.#g.qrcode.models.includes(String(o.model)) ? this.#v("QR code model is not supported by this printer", "relaxed") : (this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), "left" !== this.#h.align && this.#h.add(this.#u.align(this.#h.align)), this.#h.add(this.#u.qrcode(e, o)), "left" !== this.#h.align && this.#h.add(this.#u.align("left")), this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), this);
  }
  pdf417(e, t) {
    if (t = Object.assign({
      width: 3,
      height: 3,
      columns: 0,
      rows: 0,
      errorlevel: 1,
      truncated: !1
    }, t || {}), this.#c.embedded) throw new Error("PDF417 codes are not supported in table cells or boxes");
    return !1 === this.#g.pdf417.supported ? "object" == typeof this.#g.pdf417.fallback ? this.barcode(e, this.#g.pdf417.fallback.symbology) : this.#v("PDF417 codes are not supported by this printer", "relaxed") : (this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), "left" !== this.#h.align && this.#h.add(this.#u.align(this.#h.align)), this.#h.add(this.#u.pdf417(e, t)), "left" !== this.#h.align && this.#h.add(this.#u.align("left")), this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), this);
  }
  image(e, a, o, i, n) {
    if (this.#c.embedded) throw new Error("Images are not supported in table cells or boxes");
    if (a % 8 != 0) throw new Error("Width must be a multiple of 8");
    if (o % 8 != 0) throw new Error("Height must be a multiple of 8");
    void 0 === i && (i = "threshold"), void 0 === n && (n = 128);
    const r = e.constructor.name;
    let p, c;
    if (r.endsWith("Element") && (p = "element"), "ImageData" == r && (p = "imagedata"), "Canvas" == r && void 0 !== e.getContext && (p = "node-canvas"), "Image" == r && (p = "node-canvas-image"), "Image" == r && void 0 !== e.frames && (p = "node-read-image"), "Object" == r && e.data && e.info && (p = "node-sharp"), "View3duint8" == r && e.data && e.shape && (p = "ndarray"), "Object" == r && e.data && e.width && e.height && (p = "object"), !p) throw new Error("Could not determine the type of image input");
    if ("element" == p) {
      const t = document.createElement("canvas");
      t.width = a, t.height = o;
      const s = t.getContext("2d");
      s.drawImage(e, 0, 0, a, o), c = s.getImageData(0, 0, a, o);
    }
    if ("node-canvas" == p) {
      c = e.getContext("2d").getImageData(0, 0, e.width, e.height);
    }
    if ("node-canvas-image" == p) {
      if ("function" != typeof this.#c.createCanvas) throw new Error("Canvas is not supported in this environment, specify a createCanvas function in the options");
      const t = this.#c.createCanvas(a, o).getContext("2d");
      t.drawImage(e, 0, 0, a, o), c = t.getImageData(0, 0, a, o);
    }
    if ("node-read-image" == p && (c = new d(e.width, e.height), c.data.set(e.frames[0].data)), "node-sharp" == p && (c = new d(e.info.width, e.info.height), c.data.set(e.data)), "ndarray" == p && (c = new d(e.shape[0], e.shape[1]), c.data.set(e.data)), "object" == p && (c = new d(e.width, e.height), c.data.set(e.data)), "imagedata" == p && (c = e), !c) throw new Error("Image could not be loaded");
    if (a === c.width && o === c.height || (c = u(c, a, o, "bilinear-interpolation")), a !== c.width || o !== c.height) throw new Error("Image could not be resized");
    switch (c = s.flatten(c, [255, 255, 255]), i) {
      case "threshold":
        c = t.threshold(c, n);
        break;
      case "bayer":
        c = t.bayer(c, n);
        break;
      case "floydsteinberg":
        c = t.floydsteinberg(c);
        break;
      case "atkinson":
        c = t.atkinson(c);
    }
    return this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), "left" !== this.#h.align && this.#h.add(this.#u.align(this.#h.align)), this.#h.add(this.#u.image(c, a, o, this.#c.imageMode)), "left" !== this.#h.align && this.#h.add(this.#u.align("left")), this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), this;
  }
  cut(e) {
    if (this.#c.embedded) throw new Error("Cut is not supported in table cells or boxes");
    for (let e = 0; e < this.#c.feedBeforeCut; e++) this.#h.flush({
      forceNewline: !0
    });
    return this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), this.#h.add(this.#u.cut(e)), this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), this;
  }
  pulse(e, t, s) {
    if (this.#c.embedded) throw new Error("Pulse is not supported in table cells or boxes");
    return this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), this.#h.add(this.#u.pulse(e, t, s)), this.#h.flush({
      forceFlush: !0,
      ignoreAlignment: !0
    }), this;
  }
  raw(e) {
    return this.#h.raw(e), this;
  }
  #x(e, t) {
    return "bold" === e ? this.#u.bold(t) : "underline" === e ? this.#u.underline(t) : "italic" === e ? this.#u.italic(t) : "invert" === e ? this.#u.invert(t) : "size" === e ? this.#u.size(t.width, t.height) : void 0;
  }
  #z(e, t) {
    if (null === t) {
      return [{
        type: "text",
        payload: [...n.encode(e, "ascii")]
      }];
    }
    if ("auto" !== t) {
      const s = n.encode(e, t);
      return this.#b.codepage != this.#m[t] ? (this.#b.codepage = this.#m[t], [{
        type: "codepage",
        payload: this.#u.codepage(this.#m[t])
      }, {
        type: "text",
        payload: [...s]
      }]) : [{
        type: "text",
        payload: [...s]
      }];
    }
    const s = n.autoEncode(e, this.#f),
      a = [];
    for (const e of s) this.#b.codepage = this.#m[e.codepage], a.push({
      type: "codepage",
      payload: this.#u.codepage(this.#m[e.codepage])
    }, {
      type: "text",
      payload: [...e.bytes]
    });
    return a;
  }
  commands() {
    this.#c.autoFlush && !this.#c.embedded && this.#h.add(this.#u.flush());
    const e = [],
      t = this.#h.fetch({
        forceFlush: !0,
        ignoreAlignment: !0
      });
    for (t.length && this.#l.push(t); this.#l.length;) {
      const t = this.#l.shift(),
        s = t.filter(e => "style" === e.type && "size" === e.property).map(e => e.value.height).reduce((e, t) => Math.max(e, t), 1);
      this.#c.debug && console.log("|" + t.filter(e => "text" === e.type).map(e => e.value).join("") + "|", s), e.push({
        commands: t,
        height: s
      });
    }
    return this.#c.debug && console.log("commands", e), this.#y(), e;
  }
  encode(e) {
    const t = this.commands();
    if ("commands" === e) return t;
    const s = [];
    for (const e of t) {
      const t = [];
      for (const s of e.commands) "text" === s.type ? t.push(...this.#z(s.value, s.codepage)) : "style" === s.type ? t.push(Object.assign(s, {
        payload: this.#x(s.property, s.value)
      })) : (s.value || s.payload) && t.push(s);
      s.push(t);
    }
    if ("lines" === e) return s;
    let a = [],
      o = null;
    for (const e of s) {
      for (const t of e) a.push(...t.payload), o = t;
      "\n\r" === this.#c.newline && a.push(10, 13), "\n" === this.#c.newline && a.push(10);
    }
    return o && "pulse" === o.type && (a = a.slice(0, 0 - this.#c.newline.length)), Uint8Array.from(a);
  }
  #v(e, t) {
    if ("strict" === t || "strict" === this.#c.errors) throw new Error(e);
    return console.warn(e), this;
  }
  static get printerModels() {
    return Object.entries(y).map(e => ({
      id: e[0],
      name: e[1].vendor + " " + e[1].model
    }));
  }
  get columns() {
    return this.#h.columns;
  }
  get language() {
    return this.#c.language;
  }
  get printerCapabilities() {
    return this.#g;
  }
}
export { v as default };
