import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { logoutUser } from 'src/app/reducers/user/user.actions';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'chef-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatIconModule, TranslocoPipe],
})
export class FooterComponent {
  @Input() showLogout!: boolean;

  constructor(
    private ngrxStore: Store<State>,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  logOut(): void {
    this.ngrxStore.dispatch(logoutUser());
  }

  goBackToOrderTaking(): void {
    this.document.location.href = 'https://menu.menutech.com/login';
  }
}
