import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MatOptionSelectionChange,
  MatOptionModule,
} from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { de, enUS, es, fr, it } from 'date-fns/locale';
import { compact } from 'lodash-es';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { KdsSettings } from 'src/app/models/user';
import { State } from 'src/app/reducers';
import {
  fetchAvailableSections,
  fetchServicesList,
  setCurrentDate,
  setCurrentService,
} from 'src/app/reducers/sections/sections.actions';
import {
  clearAllSectionTickets,
  fetchAllSectionTickets,
} from 'src/app/reducers/tickets/tickets.actions';
import { getFormattedDate } from 'src/app/shared/utils.functions';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'chef-navigation-filters',
  templateUrl: './navigation-filters.component.html',
  styleUrls: ['./navigation-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class NavigationFiltersComponent implements OnChanges, OnInit {
  @Input() kdsSettings!: KdsSettings | null;
  @Input() language = 'en';
  @Input() serviceList!: string[] | null;

  date = new FormControl(new Date());
  localeMap = {
    en: enUS,
    de: de,
    fr: fr,
    es: es,
    it: it,
  };
  selectFormControl = new FormControl('');
  searchProduct = new FormControl('');
  searchTable = new FormControl('');
  searchConsumer = new FormControl('');
  services: string[] = [];
  selectedService = '';
  selectedDate = '';

  constructor(
    private activatedRout: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private destroyRef: DestroyRef,
    private ngrxStore: Store<State>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.searchProduct.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.handleSearch());
    this.searchTable.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.handleSearch());
    this.searchConsumer.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.handleSearch());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('serviceList' in changes && this.serviceList) {
      this.services = compact(this.serviceList);
      this.selectedService = this.services[0];
      const currentService =
        this.activatedRout.snapshot.queryParamMap.get('service');
      if (currentService && this.services.includes(currentService)) {
        this.selectedService = currentService;
      }
      this.selectFormControl.setValue(this.selectedService);
      this.ngrxStore.dispatch(
        setCurrentService({ service: this.selectedService }),
      );
    }
    if ('language' in changes) {
      this.dateAdapter.setLocale(
        this.localeMap[this.language as 'en' | 'de' | 'fr' | 'es' | 'it'],
      );
    }
  }

  dateChange(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      const date = getFormattedDate(event.value);
      this.selectedDate = date;
      this.clearAllFilters();
      this.ngrxStore.dispatch(clearAllSectionTickets());
      this.ngrxStore.dispatch(setCurrentDate({ date }));
      this.ngrxStore.dispatch(fetchServicesList({ date }));
    }
  }

  handleSearch(): void {
    this.ngrxStore.dispatch(
      fetchAllSectionTickets({
        item_baselang: this.searchProduct.value,
        table_number: this.searchTable.value ? +this.searchTable.value : null,
        name_room: this.searchConsumer.value,
      }),
    );
  }

  selectService(event: MatOptionSelectionChange<string>): void {
    if (event.isUserInput) {
      this.selectedService = event.source.value;
      const queryParams = {
        service: this.selectedService,
      };
      this.clearAllFilters();
      this.router.navigate([], { queryParams });
      this.ngrxStore.dispatch(clearAllSectionTickets());
      this.ngrxStore.dispatch(
        setCurrentService({ service: event.source.value }),
      );
      this.ngrxStore.dispatch(
        fetchAvailableSections({
          currentService: event.source.value,
          date: this.selectedDate,
        }),
      );
    }
  }

  clearProductFilter(event: MouseEvent, field: string): void {
    event.stopPropagation();
    switch (field) {
      case 'product':
        this.searchProduct.patchValue('');
        break;
      case 'table':
        this.searchTable.patchValue('');
        break;
      case 'consumer':
        this.searchConsumer.patchValue('');
        break;
    }
  }

  clearAllFilters(): void {
    this.searchProduct.patchValue('', { emitEvent: false });
    this.searchTable.patchValue('', { emitEvent: false });
    this.searchConsumer.patchValue('', { emitEvent: false });
  }
}
