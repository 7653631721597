import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { SectionTicket, ServiceStatus } from 'src/app/models/tickets';
import { State } from 'src/app/reducers';
import { printTicket } from 'src/app/reducers/printer/printer.actions';
import { selectCurrentService } from 'src/app/reducers/sections/sections.selectors';
import { selectIsPrinterPaired } from 'src/app/reducers/shared/shared.selectors';
import {
  fetchAllSectionTickets,
  updateTicketStatus,
} from 'src/app/reducers/tickets/tickets.actions';
import {
  selectFiredTickets,
  selectFiredTicketsSectionCount,
} from 'src/app/reducers/tickets/tickets.selectors';
import { selectConnectPrinter } from 'src/app/reducers/user/user.selectors';
import { ClockService } from 'src/app/services/clock/clock.service';
import { PageTicketsComponent } from 'src/app/shared/components/page-tickets/page-tickets.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { SectionPanelsComponent } from '../../shared/components/section-panels/section-panels.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'chef-fired',
  templateUrl: './fired.component.html',
  styleUrls: [
    '../../shared/components/page-tickets/page-tickets.component.scss',
    './fired.component.scss',
  ],
  standalone: true,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    SectionPanelsComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class FiredComponent extends PageTicketsComponent implements OnInit {
  clock$!: Observable<Date>;
  isPrinterPaired$ = this.ngrxStore.select(selectIsPrinterPaired);
  selectConnectPrinter$ = this.ngrxStore.select(selectConnectPrinter);
  tickets$ = this.ngrxStore.select(selectFiredTickets);
  ticketsSectionCount$ = this.ngrxStore.select(selectFiredTicketsSectionCount);

  isPrinterPaired = false;
  userConnectPrinter = false;

  constructor(
    private clockService: ClockService,
    protected override ngrxStore: Store<State>,
  ) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    this.clock$ = this.clockService.getClock();
    this.isPrinterPaired$.subscribe((val) => {
      this.isPrinterPaired = val;
    });
    this.selectConnectPrinter$.subscribe((val) => {
      this.userConnectPrinter = val;
    });
    this.ngrxStore
      .select(selectCurrentService)
      .pipe(take(1))
      .subscribe((val) => {
        if (val !== undefined)
          this.ngrxStore.dispatch(
            fetchAllSectionTickets({ service_status: 3 }),
          );
      });
  }

  printMultipleTickets(tickets: SectionTicket[]) {
    tickets.forEach((ticket, idx) => {
      setTimeout(() => {
        this.ngrxStore.dispatch(printTicket({ ticket }));
      }, 500 * idx);
    });
  }

  override updateTicketStatus(
    status: ServiceStatus,
    data: { ids: number[]; section: string; ticket?: SectionTicket },
  ) {
    this.ngrxStore.dispatch(
      updateTicketStatus({
        ids: data.ids,
        data: { service_status: status + 1 },
        section: data.section,
      }),
    );
    if (data.ticket && this.userConnectPrinter && this.isPrinterPaired) {
      this.ngrxStore.dispatch(printTicket({ ticket: data.ticket }));
    }
  }

  printTicket(ticket: SectionTicket) {
    this.ngrxStore.dispatch(printTicket({ ticket }));
  }
}
