import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { defer, EMPTY, from, mergeMap, withLatestFrom } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ThermalPrintService } from 'src/app/services/thermalprint/thermal-print.service';

import { State } from '..';
import { selectKdsSettings } from '../user/user.selectors';
import { KdsSettings } from './../../models/user';
import {
  setPairedPrinter,
  setPrinterConnectionStatus,
  showSnackbarMessage,
} from './../shared/shared.actions';
import * as PrinterActions from './printer.actions';

@Injectable()
export class PrinterEffects {
  cutPaper$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PrinterActions.cutPaper),
        tap(() => {
          this.printerService.cutPaper();
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  feedPaper$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PrinterActions.feedPaper),
        tap(() => {
          this.printerService.feedPaper();
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  connectPrinter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrinterActions.connectPrinter),
      switchMap(({ openFindPrinter }) =>
        defer(() => from(this.printerService.connectPrinter())).pipe(
          switchMap((device) => [
            setPairedPrinter({
              payload: this.printerService.getPrinterName(device),
            }),
            setPrinterConnectionStatus({ connection: true }),
          ]),
          catchError((e: any) => {
            const errorMessages = {
              connection_lost:
                'settings.thermal-printer-settings.error-reconnect-refresh',
              configuration_not_loaded:
                'settings.thermal-printer-settings.error-config-unplug-refresh',
              device_busy:
                'settings.thermal-printer-settings.error-unplug-refresh',
            };
            type ErrorMessageKey = keyof typeof errorMessages;
            console.error(e);
            if (Object.keys(errorMessages).includes(e['status'])) {
              return [
                showSnackbarMessage({
                  message: this.transloco.translate(
                    errorMessages[e.status as ErrorMessageKey],
                  ),
                  snackClass: 'error-snack',
                }),
                setPrinterConnectionStatus({ connection: false }),
                setPairedPrinter({
                  payload: this.printerService.getPrinterName(e['device']),
                }),
              ];
            }
            const actions: Action<string>[] = [];
            if (openFindPrinter) actions.push(PrinterActions.findPrinter());
            return actions;
          }),
        ),
      ),
    ),
  );

  disconnectPrinter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrinterActions.disconnectPrinter),
      tap(() => {
        this.printerService.disconnectPrinter();
      }),
      mergeMap(() => [
        setPairedPrinter({ payload: null }),
        setPrinterConnectionStatus({ connection: false }),
      ]),
      catchError(() => EMPTY),
    ),
  );

  findPrinter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrinterActions.findPrinter),
      switchMap(() =>
        defer(() => from(this.printerService.requestPrinter())).pipe(
          switchMap(() => [PrinterActions.connectPrinter({})]),
          catchError((e: any) => {
            if (e.status === 'not_supported') {
              return [
                showSnackbarMessage({
                  message: this.transloco.translate(
                    'settings.thermal-printer-settings.error-not-supported',
                  ),
                  snackClass: 'error-snack',
                }),
              ];
            }
            // cancelled dialog
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  printTicket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PrinterActions.printTicket),
        withLatestFrom(this.ngrxStore.select(selectKdsSettings)),
        tap(([{ ticket }, settings]) => {
          this.printerService.printTicket(ticket, settings as KdsSettings);
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private ngrxStore: Store<State>,
    private printerService: ThermalPrintService,
    private transloco: TranslocoService,
  ) {}
}
