import {
  Component,
  Injector,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { detect } from 'detect-browser';
import {
  MatDatepickerInput,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { TranslocoPipe } from '@jsverse/transloco';
import { KeyValuePipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { ServerFieldErrorDirective } from '../../directives/server-field-error.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'chef-field-with-errors',
  templateUrl: './field-with-errors.component.html',
  styleUrls: ['./field-with-errors.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FieldWithErrorsComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    ServerFieldErrorDirective,
    MatButtonModule,
    MatCheckboxModule,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class FieldWithErrorsComponent implements OnDestroy {
  private autofocusEnabled = false;
  isFirefox = detect()?.name === 'firefox';
  isSafari = detect()?.name === 'safari';

  @Input() autofocus = false;
  @Input() formControlName!: string;
  @Input() icon!: string;
  @Input() label!: string;
  @Input() lang!: string;
  @Input() matDatepickerParse!: any;
  @Input() multiple = false;
  @Input() optionLabel!: string;
  @Input() optionLabelMulti!: boolean;
  @Input() placeholder!: string;
  @Input() required = false;
  @Input() selectOptions!: any[];
  @Input() showProgressBar!: boolean;
  @Input() suffixIcon!: string;
  @Input() translationKey!: string;
  @Input() type!:
    | 'text'
    | 'password'
    | 'email'
    | 'select'
    | 'date'
    | 'checkbox'
    | 'textarea'
    | 'number';
  @Input() valueField!: string;

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective | null;

  _datepickerInput!: MatDatepickerInput<Date>;
  @ViewChild('datepickerInput', { read: MatDatepickerInput })
  set datepickerInput(value: MatDatepickerInput<Date>) {
    this._datepickerInput = value;
  }
  unlisten!: () => void;

  constructor(private injector: Injector) {}

  get control(): FormControl {
    return this.controlContainer?.control?.get(
      this.formControlName,
    ) as FormControl;
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  blur(event: FocusEvent): void {
    if (this.autofocus && this.autofocusEnabled) {
      const inputElement = event?.target as HTMLInputElement;
      requestAnimationFrame(() => inputElement?.focus());
    }
  }

  openDatepicker(): void {
    this._datepickerInput?._datepicker.open();
  }

  writeValue(obj: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(obj);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  changeDate(event: MatDatepickerInputEvent<Date>): void {
    this.control.setValue(event?.value);
  }

  ngOnDestroy(): void {
    this.unlisten?.();
  }
}
